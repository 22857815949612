// React Base Component
import React from 'react'

// CSS Module
import styles from './modules/CoverImage.module.css'

class CoverImage extends React.Component {
    render() {
        return (
            <div className={styles.CoverImage} style={{ backgroundImage: `url('${this.props.src}')` }}></div>
        )
    }
}

export default CoverImage
