import React from 'react'

//Editor Component
import TextEditor from './TextEditor'

//Reactstrap Componenets
import {
    Row,
    Col,
    Input,
    Container,
    Button
} from 'reactstrap'
//FontAwsome Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons'

//Module CSS
import styles from './modules/NewPost.module.css'

//Custom Components
import Header from '../global/Header'
import Announcement from '../announcement/Announcement'
import {MediaModal} from './NewBlog'
import Success from './Success'
import Error from './Error'
import recaptcha from '../helper/ReCaptcha'


class NewAnncouncement extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
             alert: false,
             IsOpen: false,
             title: "" ,
             url: undefined,
             media: "",
             content: "",
             summary: "",
             setUrl: false,
             preview: false,
             success: false
        }

        this.newAnnouncement = (data) => {
            recaptcha.getReCaptchaToken(token => {
                data.gToken = token

                fetch('/api/announcement', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => {
                    if (res.ok) {
                        this.setState({success: true})
                        return res
                    }
                    throw new Error ('Can not post the blog!')
                }).catch(err => {
                    this.setState({error: true})
                    console.error(err.message)
                })
            })
        }

        this.updateAnnc = (data) => {
            recaptcha.getReCaptchaToken(token => {
                data.gToken = token

                fetch('/api/manage/announcement/update-annc', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => {
                    if (res.ok) {
                        this.props.setEditorState()
                        return res
                    } else {
                        throw new Error ('Can not update blog!')
                    }
                }).catch(err => {
                    this.setState({error: true})
                    console.error(err.message)
                })
            })
        }
        this.post = () => {
            const {media, title, summary, content } = this.state
            const url = this.props.url
            const postData = {
               media, url, title, summary, content
           }
               this.props.manage ? this.updateAnnc(postData) : this.newAnnouncement({media,title,summary,content})
          
           
        }
        
        this.setContent = async (incomeContent) => {
            this.setState({content: incomeContent})
        }

        this.setMediaItem = async (incomeMedia) => {
            this.setState({media: incomeMedia, IsOpen: false})
        }

        this.setPreview = () => {
            this.setState({preview: !this.state.preview})
        }
        this.preiew = () => {
            this.setPreview()
        }
    }

    componentDidMount() {
        if(this.props.data) {
            this.setState({
                title:   this.props.data.title,
                media:   this.props.data.media,
                content: this.props.data.media,
                content: this.props.data.content,
                summary: this.props.data.summary
            })
        }
    }
    render() {
        const {media, title, content} = this.state
        const dataObj = {media, title, content}
        return(
            this.state.success || this.state.error ? (
                this.state.success ? (<Success annc/>) : (<Error annc setErrState={() => this.setState({error: false})}/>)
            ) : 
            
            this.state.preview ? (
                <Announcement preview setPreview={this.setPreview} data={dataObj}/>
            ) : (
                <Container>
                    <Row>
                        <Col>
                            <Header value={this.props.manage ? "Etkinliği Düzenle" : "Yeni Etkinlik"} hide={this.props.hideUserList ? true : null} />
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg="11" className="m-1 p-0">
                        <Row>
                            <Col className="my-1">
                                <Input className="text-center" placeholder="Başlığı buraya giriniz..." value={this.state.title} onChange={(event) => this.setState({title: event.target.value})} />
                                
                            </Col>
                        </Row>
                            {
                                this.state.media ? (
                                    <div className={styles.Container}>
                                        <div className={styles.Aspect16x9 + " m-auto d-flex justify-content-center"}
                                        style={{backgroundImage: `url(${this.state.media})`}} onClick={()=> (this.setState({IsOpen: true}))}> 
                                            <div className={styles.Overlay}>
                                                    <p style={{fontSize: '50px'}}>Resmi değiştir <FontAwesomeIcon icon={faExchangeAlt}/></p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                ) : (
                                        <div className={styles.UploadContainer}>
                                            <div className={styles.UploadContent + " text-muted"} onClick={()=> (this.setState({IsOpen: true}))}>
                                                Bir kapak resmi ekleyin
                                                
                                            </div>
                                        </div>
                                )
                            }
                            {
                                this.state.IsOpen ? (
                                    <MediaModal isOpen={this.state.IsOpen} toggle={() => this.setState({ IsOpen: !this.state.IsOpen })} setMediaItem={this.setMediaItem} />
                                ) : null
                            }
                        <Row>
                            <Col className="my-1">
                                    <TextEditor setContent={this.setContent} content={this.state.content}/>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col className="my-1">
                                <Input type="textarea" placeholder="İçerik özetini buraya yazınız" value={this.state.summary} onChange={(event) => this.setState({summary: event.target.value})}/>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-end" >
                            <Col xs="auto" className="pr-1 my-1">
                                <Button color="dark" outline onClick={this.preiew}>Önizle</Button>
                            </Col>
                            <Col xs="auto" className="my-1">
                                <Button color="dark" onClick={this.post}>Gönder</Button>
                            </Col>
                        </Row>
                        </Col>
                    </Row>
                </Container>
            )
        )
    }
    
}

export default NewAnncouncement