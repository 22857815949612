// React Base Component

import React from 'react'

// React Router Components
import { Link, NavLink } from 'react-router-dom'

// React Redux Connection
import { connect } from 'react-redux'

// Reactstrap Components
import {
    Button,
    ButtonGroup,
    Col,
    Container,
    Row,
    Card,
    CardBody
} from 'reactstrap'

// Custom Components
import Loader from '../global/Loader'
import Banner from './Banner'
import styles from './modules/LandingPage.module.css'

class RecentList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            list: undefined,
            loading: true
        }

        /**
         * Format datestring to TR locale
         * @param {string} date The ISO-Date string 
         */
         this.formatDate = (date = "") => {
            return (new Date(date)).toLocaleDateString('tr-TR')
        }
        
        /**
         * Format datestring to ss:mm:hh
         * @param {string} date The ISO-Date string 
         */
        this.formatHour = (date = "") => {
            return (new Date(date)).toTimeString().split(' ')[0]
        }

        this.fetchData = () => {
            const target = this.props.value == 'blog' ? "/blog" : "/announcement"
            fetch('/api' + target + '/recent').then( res => {
                if (res.ok) return res.json()
                throw new Error('Can not get Recents!')
            }).then(data => {
                this.setState({ list: data, loading: false })
            }).catch(err => {
                console.error(err)
            })
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    render() {
        return (
            this.state.loading ? (
                <Loader />
            ) : (
                <Row>{
                    this.state.list.map(element => (
                        <Col key={element.url} xl="4" xs="12" md="4">
                            <Card className="border-0" style={{ backgroundColor: "transparent" }}>
                                <Link to={(this.props.value == "blog" ? '/blogs': '/announcements') + element.url}>
                                    <div style={{backgroundImage:  `url(${element.media})` || "/logo512.png"}} alt={element.alt} className={[styles.Aspect16x9, styles.CardImg].join(' ')} />
                                </Link>
                                <CardBody>
                                    <span className="d-flex justify-content-between">
                                        <p className="text-muted">{this.formatDate(element.date)}</p>
                                        <p className="text-muted">{this.formatHour(element.date)}</p>
                                    </span>
                                    <Link to={(this.props.value == "blog" ? '/blogs': '/announcements') + element.url} className="text-dark">
                                        <h5 className="text-center">{element.title}</h5>
                                    </Link>
                                    <p className="text-center">{element.summary}</p>
                                </CardBody>
                            </Card>
                        </Col>
                    ))
                }</Row>
            )
        )
    }
}

class RecentGrid extends React.Component {
    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <p className="display-4 px-3 text-muted pt-5" style={{ fontSize: "35px" }}>{this.props.blog ? "Son Bloglar" : "Son Etkinlikler"}</p>
                    </Col>
                    <Col xs="auto"  className="display-4 px-3 text-muted pt-5">
                        <Button outline tag={Link} to={(this.props.blog ? '/blogs': '/announcements')}>
                            {this.props.blog ? "Tüm Bloglar" : "Tüm Etkinlikler"}
                        </Button>
                    </Col>
                </Row>
                <hr/>
                <RecentList value={this.props.blog ? 'blog' : 'announcement'}/>
            </div>
        )
    }
}

class LandingPageContent extends React.Component {
    render() {
        return (
                <Row noGutters>
                    <Col>
                        <Banner />
                        <Container fluid="xl">
                            <RecentGrid blog/>
                            <RecentGrid annc/>
                        </Container>
                    </Col>
                </Row>
        )
    }
}

class LandingPage extends React.Component {
    render() {
        return (
            <div>
                <LandingPageContent />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        pathname: state.pathname
    }
}

const mapDipatchToProps = {
    // empty
}

export default connect(mapStateToProps, mapDipatchToProps)(LandingPage)
