import React from 'react'

//Editor Component
import TextEditor from './TextEditor'

//Reactstrap Componenets
import {
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    Container,
    Button
} from 'reactstrap'

//FontAwsome Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons'

//Custom Componenets
import styles from './modules/NewPost.module.css'
import Loader from '../global/Loader'
import Header from '../global/Header'
import Contest from '../contest/Contest'
import Blog from '../blog/Blog'
import Success from './Success'
import Error from './Error'
import recaptcha from '../helper/ReCaptcha'

class MediaItem extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            height: 0
        }

        this.reference = React.createRef()

    }

    componentDidMount() {
        this.setState({ height: this.reference.current.offsetWidth * 0.5625 })
    }
    
    render() {
        return (
                 
            <Col xs="12" md="6" xl="6" className="mb-3">
                <div ref={this.reference} style={{ height: this.state.height }} onClick={() => this.props.setMediaItem(this.props.data.src)}>
                    <div className={styles.MediaItem} style={{ backgroundImage: `url(${this.props.data.src})` }} >
                        <div className={styles.MediaItemContent}>
                            {this.props.data.alt}
                        </div>
                    </div>
                </div>
            </Col>
           
        )
    }
}

class MediaModal extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
             isOpen: this.props.isOpen,
             mediaList: undefined,
             loading: true
        }

        this.reference = React.createRef()
    }
    componentDidMount() {
        const target = (this.props.profile ? "avatar" : "image")
        fetch('/api/media-list/' + target).then(res => {
            if(res.ok) return res.json()
            throw new Error ('Can not get media list!')
        }).then(data => {
            this.setState({mediaList:  JSON.parse(JSON.stringify(data)), loading: false})
        }).catch(err => {
            console.error(err.message)
        })
    }
    render() {
        return(
            <Modal {...this.props} scrollable>
                <ModalHeader>Bir resim seçin</ModalHeader>
                <ModalBody>
                     <Container fluid>
                         <Row className="align-items-stretch">
                            {
                                this.state.loading ? (
                                    <Loader/>
                                    
                                ) : (
                                    this.state.mediaList.map((element,index) => (
                                            <MediaItem key={index} data={element} setMediaItem={this.props.setMediaItem} avatar={this.props.profile}/>
                                    ))
                                )
                            }
                         </Row>
                     </Container>
                </ModalBody>
               <ModalHeader>Veya bir resim yükleyin</ModalHeader>
               <ModalBody>
                   <Col >
                        {
                            this.props.profile ? (
                                <Contest modal setMediaItem={this.props.setMediaItem} profile/>
                            ) : (
                                <Contest modal setMediaItem={this.props.setMediaItem}/>
                            )
                        } 
                   </Col>
               </ModalBody>
            </Modal>
        )
    }
    
}
class NewBlog extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
             alert: false,
             IsOpen: false,
             title: "" ,
             url: undefined,
             media: "",
             content: "",
             summary: "",
             setUrl: false,
             preview: false,
             success: false,
        }

        this.newBlog = (data) => {
            recaptcha.getReCaptchaToken(token => {
                data.gToken = token

                fetch('/api/blog', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => {
                    if (res.ok) {
                        this.setState({success: true})
                        return res
                    }
                    throw new Error ('Can not post the blog!')
                }).catch(err => {
                    this.setState({error: true})
                    console.error(err.message)
                })
            })
        }

        this.updateBlog = (data) => {
            recaptcha.getReCaptchaToken(token => {
                data.gToken = token

                fetch('/api/manage/blog/update-blog', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => {
                    if (res.ok) {
                        this.props.setEditorState()
                        return res.json()
                    } else {
                        throw new Error ('Can not update blog!')
                    }
                }).catch(err => {
                    this.setState({error: true})
                    console.error(err.message)
                })
            })

        }
        this.post = () => {
            const {media, title, summary, content } = this.state
            const url = this.props.url
            const postData = {
               media, url, title, summary, content
           }
               this.props.manage ? this.updateBlog(postData) : this.newBlog({media,title,summary,content})
          
           
        }
        
        this.setContent = async (incomeContent) => {
            this.setState({content: incomeContent})
        }

        this.setMediaItem = async (incomeMedia) => {
            this.setState({media: incomeMedia, IsOpen: false})
        }

        this.setPreview = () => {
            this.setState({preview: !this.state.preview})
        }
        this.preiew = () => {
            this.setPreview()
        }
    }

    componentDidMount() {
        if(this.props.data) {
            this.setState({
                title:   this.props.data.title,
                media:   this.props.data.media,
                content: this.props.data.media,
                content: this.props.data.content,
                summary: this.props.data.summary
            })
        }
    }
    render() {
        const {media, title, content} = this.state
        const dataObj = {media, title, content}
        return(
            this.state.success || this.state.error ? (
                this.state.success ? (<Success blog/>) : (<Error blog setErrState={() => this.setState({error: false})}/>)
            ) : 
            
            this.state.preview ? (
                <Blog preview setPreview={this.setPreview} data={dataObj}/>
            ) : (
                <Container>
                    <Row>
                        <Col>
                            <Header value={this.props.manage ? "Blogu Düzenle" : "Yeni Blog"} hide={this.props.hideUserList ? true : null}/>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg="11" className="m-1 p-0">
                        <Row>
                            <Col className="my-1">
                                <Input className="text-center" placeholder="Başlığı buraya giriniz..." value={this.state.title} onChange={(event) => this.setState({title: event.target.value})} />
                                
                            </Col>
                            </Row>
                            {
                                this.state.media ? (
                                    <div className={styles.Container}>
                                        <div className={styles.Aspect16x9 + " m-auto d-flex justify-content-center"}
                                        style={{backgroundImage: `url(${this.state.media})`}} onClick={()=> (this.setState({IsOpen: true}))}> 
                                            <div className={styles.Overlay}>
                                                    <p style={{fontSize: '50px'}}>Resmi değiştir <FontAwesomeIcon icon={faExchangeAlt}/></p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                ) : (
                                        <div className={styles.UploadContainer}>
                                            <div className={styles.UploadContent + " text-muted"} onClick={()=> (this.setState({IsOpen: true}))}>
                                                Bir kapak resmi ekleyin
                                                
                                            </div>
                                        </div>
                                )
                            }
                            {
                                this.state.IsOpen ? (
                                    <MediaModal isOpen={this.state.IsOpen} toggle={() => this.setState({ IsOpen: !this.state.IsOpen })} setMediaItem={this.setMediaItem} />
                                ) : null
                            }
                        <Row>
                            <Col className="my-1">
                                    <TextEditor setContent={this.setContent} content={this.state.content}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="my-1">
                                <Input type="textarea" placeholder="İçerik özetini buraya yazınız" value={this.state.summary} onChange={(event) => this.setState({summary: event.target.value})}/>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-end" >
                            <Col xs="auto" className="pr-1 my-1">
                                <Button color="dark" outline onClick={this.preiew}>Önizle</Button>
                            </Col>
                            <Col xs="auto" className="my-1">
                                <Button color="dark" secondary onClick={this.post}>Gönder</Button>
                            </Col>
                        </Row>
                        </Col>
                    </Row>
                </Container>
            )
        )
    }
    
}

export default NewBlog
export {
    MediaItem,
    MediaModal
}
