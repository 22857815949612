const loginHandle = (info = {}) => {
    return {
        type: 'USER_LOGIN',
        payload: info
    }
}

const updateTheme = (theme = {}) => {
    return {
        type: 'SITE_THEME',
        payload: {
            primary: theme.primary,
            secondary: theme.secondary,
            light: theme.light,
            dark: theme.dark
        }
    }
}

const updateCurrentURL = (pathname = "") => {
    return {
        type: 'SITE_CURRENT_PATH',
        payload: pathname
    }
}

const updateURLMatch = (isExact = false) => {
    return {
        type: 'SITE_EXACT_MATCH',
        payload: isExact
    }
}

const setLayoutScale = (width, height) => {
    return {
        type: 'LAYOUT_SCALE',
        payload: { width, height }
    }
}

const toggleUserList = (forceClose) => {
    return {
        type: 'LAYOUT_USER_LIST',
        payload: forceClose
    }
}

export {
    loginHandle,
    updateTheme,
    updateCurrentURL,
    updateURLMatch,
    setLayoutScale,
    toggleUserList
}
