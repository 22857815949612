// React Base Component
import React from 'react'

// Reactstrap Components
import {
    Button,
    Col,
    CustomInput,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    PopoverBody,
    PopoverHeader,
    Row,
    UncontrolledPopover
} from 'reactstrap'

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCaretDown,
    faGlobeAmericas,
    faSearch
} from '@fortawesome/free-solid-svg-icons';

// Custom Components
import TableList from './TableList'
import Loader from '../global/Loader'
import { faFacebook, faGithubAlt, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import recaptcha from '../helper/ReCaptcha'

class Filter extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            filter: {
                search: "",
                active: true,
                passive: true,
                regular: true,
                editor: true,
                admin: true,
            },
            isOpen: false,
            change: false
        }

        this.handleChange = (event) => {
            const update = { ...this.state }
            update.filter[event.target.name] = event.target.name === "search" ? event.target.value : event.target.checked
            update.change = true
            this.setState(update)
        }
    }

    componentDidUpdate() {
        if (this.state.change) {
            this.setState({ change: false }, () => {
                this.props.update(this.state.filter)
            })
        }
    }

    render() {
        return (
            <Form className="py-1">
                <Row noGutters>
                    <Col className="flex-grow-1 flex-shrink-0">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Button color="dark" id="filters" type="button" block>
                                    Filtreler <FontAwesomeIcon icon={faCaretDown} />
                                </Button>
                                <UncontrolledPopover trigger="legacy" placement="bottom" isOpen={this.state.isOpen} target="filters" toggle={() => this.setState({ isOpen: !this.state.isOpen })}>
                                    <PopoverHeader>Filtreler</PopoverHeader>
                                    <PopoverBody>
                                        <FormGroup>
                                            <Label>Durum</Label>
                                            <div>
                                                <CustomInput type="checkbox" name="active" id="active" label={"Aktif"} checked={this.state.filter.active} onChange={this.handleChange} />
                                                <CustomInput type="checkbox" name="passive" id="passive" label={"Pasif"} checked={this.state.filter.passive} onChange={this.handleChange} />
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Rol</Label>
                                            <div>
                                                <CustomInput type="checkbox" name="regular" id="regular" label={"Standart Kullanıcı"} checked={this.state.filter.regular} onChange={this.handleChange} />
                                                <CustomInput type="checkbox" name="editor" id="editor" label={"Editör"} checked={this.state.filter.editor} onChange={this.handleChange} />
                                                <CustomInput type="checkbox" name="admin" id="admin" label={"Admin"} checked={this.state.filter.admin} onChange={this.handleChange} />
                                            </div>
                                        </FormGroup>
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </InputGroupAddon>
                            <Input type="text" name="search" placeholder="Hızlı Arama" value={this.state.filter.search} onChange={this.handleChange} />
                            <InputGroupAddon addonType="append">
                                <Button color="dark">
                                    <FontAwesomeIcon icon={faSearch} />
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>
            </Form>
        )
    }
}

class Editor extends Modal {
    constructor(props) {
        super(props)

        this.state = {
            data: {}
        }

        this.handleChange = (event) => {
            const update = { ...this.state }
            update.data[event.target.name] = event.target.name === 'status' ? event.target.checked : event.target.value
            this.setState(update)
        }

        this.handleSubmit = () => {
            recaptcha.getReCaptchaToken(token => {
                fetch('/api/manage/user', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ ...this.state.data, gToken: token })
                }).then(res => {
                    if (res.ok) {
                        this.props.save()
                    } else {
                        throw new Error('Failed to save changes.')
                    }
                }).catch(err => {
                    console.error(err.message)
                })

            })
        }
    }

    componentDidMount() {
        if (this.props.data.uname) {
            this.setState({ data: { ...this.props.data } })
        }
    }

    componentDidUpdate(oldProps) {
        if (this.props.data.uname && ((oldProps.data.uname !== this.props.data.uname) || (oldProps.isOpen === false && this.props.isOpen === true))) {
            this.setState({ data: { ...this.props.data } })
        }
    }

    render() {
        return (
            <Modal {...this.props} scrollable>
                <ModalHeader>Kullanıcı Seçenekleri</ModalHeader>
                <ModalBody>
                    <Form>
                        <h5 className="mb-3">{this.state.data.uname}</h5>
                        <FormGroup>
                            <Label>Durum</Label>
                            <CustomInput
                                type="switch"
                                name="status"
                                id="status-switch"
                                label={this.state.data.status ? "Aktif" : "Pasif"}
                                checked={this.state.data.status}
                                onChange={this.handleChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Rol</Label>
                            <Input type="select" name="role" value={this.state.data.role} onChange={this.handleChange}>
                                <option value="0">Standart Kullanıcı</option>
                                <option value="1">Editor</option>
                                <option value="2">Admin</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>İsim / Soyisim</Label>
                            <InputGroup className="mb-1">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText style={{ width: '5rem' }}>İsim</InputGroupText>
                                </InputGroupAddon>
                                <Input type="text" name="fname" value={this.state.data.fname} onChange={this.handleChange} />
                            </InputGroup>
                            <InputGroup className="mb-1">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText style={{ width: '5rem' }}>Soyisim</InputGroupText>
                                </InputGroupAddon>
                                <Input type="text" name="lname" value={this.state.data.lname} onChange={this.handleChange} />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label>Açıklama</Label>
                            <Input type="textarea" name="description" onChange={this.handleChange} value={this.state.data.description} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Sosyal Media</Label>
                            <InputGroup className="mt-1">
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText style={{ width: '2.2em', fontSize: '14pt' }}>
                                        <FontAwesomeIcon icon={faInstagram} />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input type='text' onChange={this.handleChange} value={this.state.data.instagram} name="instagram" placeholder="<instagram>" />
                            </InputGroup>
                            <InputGroup className="mt-1">
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText style={{ width: '2.2em', fontSize: '14pt' }}>
                                        <FontAwesomeIcon icon={faFacebook} />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input type='text' onChange={this.handleChange} value={this.state.data.facebook} name="facebook" placeholder="<facebook>" />
                            </InputGroup>
                            <InputGroup className="mt-1">
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText style={{ width: '2.2em', fontSize: '14pt' }}>
                                        <FontAwesomeIcon icon={faTwitter} />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input type='text' onChange={this.handleChange} value={this.state.data.twitter} name="twitter" placeholder="<twitter>" />
                            </InputGroup>
                            <InputGroup className="mt-1">
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText style={{ width: '2.2em', fontSize: '14pt' }}>
                                        <FontAwesomeIcon icon={faLinkedin} />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input type='text' onChange={this.handleChange} value={this.state.data.linkedin} name="linkedin" placeholder="<linkedin>" />
                            </InputGroup>
                            <InputGroup className="mt-1">
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText style={{ width: '2.2em', fontSize: '14pt' }}>
                                        <FontAwesomeIcon icon={faGithubAlt} />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input type='text' onChange={this.handleChange} value={this.state.data.github} name="github" placeholder="<github>" />
                            </InputGroup>
                            <InputGroup className="mt-1">
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText style={{ width: '2.2em', fontSize: '14pt' }}>
                                        <FontAwesomeIcon icon={faGlobeAmericas} />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input type='text' onChange={this.handleChange} value={this.state.data.website} name="website" placeholder="<website>" />
                            </InputGroup>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="dark" outline onClick={this.props.toggle}>İptal</Button>
                    <Button color="dark" onClick={this.handleSubmit}>Kaydet</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

class User extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            raw: [],
            data: [],
            loading: true,
            failed: false,
            failMessage: "",
            editorIsOpen: false,
            editTarget: "",
            sort: {
                index: -1,
                order: false
            }
        }

        this.sort = async (params) => {
            const update = { ...this.state }
            update.sort = { ...params }
            update.data = this.toSortable(JSON.parse(JSON.stringify(update.raw)))

            if (params.index !== -1) {
                update.data.sort((e1, e2) => {
                    if (typeof e1[params.index] === 'string') {
                        return e1[params.index].localeCompare(e2[params.index], "tr") * (params.order ? 1 : -1)
                    } else {
                        return ((e1[params.index] < e2[params.index]) ? -1 : ((e1[params.index] > e2[params.index]) ? 1 : 0)) * (params.order ? 1 : -1)
                    }
                })
            }

            update.data.forEach(element => {
                element[2] = element[2] ? element[2].toLocaleDateString("tr") : null
            })

            this.setState(update)
        }

        this.filter = async (filters = {}) => {
            if (Object.keys(filters).length == 0) {
                this.setState({ data: Array.from(this.state.raw) })
            } else {
                const update = { ...this.state }

                update.data = this.state.raw.filter(element => {
                    const regexp = new RegExp(filters.search.replace(/(^\s+)|(\s+$)/g, "").replace(/\s+/g, " "), "ig")
                    const search = !filters.search || [element.uname, element.fname + " " + element.lname].join('|').match(regexp) ? true : false

                    const active = filters.active ? element.status === 1 : false
                    const passive = filters.passive ? element.status === 0 : false

                    const regular = filters.regular ? element.role === 0 : false
                    const editor = filters.editor ? element.role === 1 : false
                    const admin = filters.admin ? element.role === 2 : false

                    return search && (active || passive) && (regular || editor || admin)
                })

                update.data = this.toReadable(update.data)

                this.setState(update)
            }
        }

        this.edit = async (index) => {
            const target = { ...this.state.raw.find((element) => element.uname == this.state.data[index][0]) }
            this.setState({ editorIsOpen: true, editTarget: target })
        }

        this.save = () => {
            this.setState({ editorIsOpen: false, loading: true }, () => {
                this.fetchUserTable()
            })
        }

        this.toSortable = (array) => {
            const newArray = []
            const roleLookup = ["Standart Kullanıcı", "Editor", "Admin"]

            array.forEach(element => {
                newArray.push([
                    element.uname,                                  // uname
                    `${element.fname} ${element.lname}`,            // name
                    element.date ? new Date(element.date) : null,   // date
                    element.status ? "Aktif" : "Pasif",             // status    
                    roleLookup[element.role]                        // role
                ])
            })

            return newArray
        }

        this.toReadable = (array) => {
            const newArray = []
            const roleLookup = ["Standart Kullanıcı", "Editor", "Admin"]

            array.forEach(element => {
                newArray.push([
                    element.uname,                                     // uname
                    `${element.fname} ${element.lname}`,               // name
                    (new Date(element.date)).toLocaleDateString("tr"), // date
                    element.status ? "Aktif" : "Pasif",                // status    
                    roleLookup[element.role]                           // role
                ])
            })

            return newArray
        }

        this.fetchUserTable = () => {
            fetch('/api/manage/user').then(res => {
                if (res.ok) return res.json()
                throw new Error('Cannot GET /api/manage/user')
            }).then(data => {
                this.setState({ raw: data, data: this.toReadable(data), loading: false })
            }).catch(err => {
                this.setState({ loading: false, failed: true, failMessage: err.message })
                console.error(err.message)
            })
        }
    }

    componentDidMount() {
        this.fetchUserTable()
    }

    render() {
        return (
            <div>
                <Editor data={this.state.editTarget} save={this.save} isOpen={this.state.editorIsOpen} toggle={() => this.setState({ editorIsOpen: !this.state.editorIsOpen })} />
                <Row>
                    <Col>
                        <Filter update={this.filter} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.state.loading ? <Loader /> : (
                            <TableList
                                sort={this.sort}
                                sortParams={this.state.sort}
                                edit={this.edit}
                                headers={["Kullanıcı Adı", 'İsim / Soyisim', 'Tarih', 'Durum', 'Rol']}
                                data={this.state.data}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default User
