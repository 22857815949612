// React Base Component
import React from 'react'

//Custom Components
import Reply from './Reply'

class ReplyList extends React.Component {
    render() {
        return (
            <div>
                {this.props.replies.map((element, index) => (
                    <Reply keu={index} fetchCommentData={this.props.fetchCommentData} reply={element} />
                ))}
            </div>
        )
    }

}

export default ReplyList