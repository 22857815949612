// React Base Component
import React from 'react'

// React Router Components
import {
    Link, Redirect
} from 'react-router-dom'

// Reactstrap Components
import {
    Container,
    Col,
    Row
} from 'reactstrap'

// Custom Components
import Social from '../global/Social'
import Header from '../global/Header'
import Loader from '../global/Loader'

// CSS Module
import styles from './modules/AboutUs.module.css'

class BoardMember extends React.Component {
    render() {
        return (
            <Col className="mb-4">
                <Link className={[styles.Member, 'text-decoration-none', 'text-dark', 'p-3'].join(" ")} to={'/profile/' + this.props.data.uname}>
                    <Row noGutters>
                        <Col xs="3">
                            <div className="">
                                <div className={styles.Avatar} style={{ backgroundImage: `url(${this.props.data.avatar})` }} />
                            </div>
                        </Col>
                        <Col xs="9" className="p-3">
                            <h6 className="m-0">{this.props.data.name}</h6>
                            <p>
                                <small className="text-muted">
                                    {this.props.data.role || <em>Role belirtilmemiş</em>}
                                </small>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="pt-3">{this.props.data.description}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <small>{this.props.data.uname + "@student.atilim.edu.tr"}</small>
                        </Col>
                    </Row>
                </Link>
            </Col>
        )
    }
}

class BoardMemberList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            members: [],
            loading: false
        }
    }

    componentDidMount() {
        this.setState({ loading: true }, () => {
            fetch('/api/about/management').then(res => {
                if (res.ok) return res.json()
                throw new Error('Cannot GET board members')
            }).then(data => {
                this.setState({ members: data, loading: false })
            })
        })
    }

    render() {
        return (
            <Row xs="1" xl="2" className="pt-3">
                {this.state.loading ? <Loader /> : this.state.members.map((item, index) => <BoardMember key={index} data={item} />)}
            </Row>
        )
    }
}

class AboutUs extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            failed: false,
            info: {}
        }
    }

    componentDidMount() {
        this.setState({ loading: true }, () => {
            fetch('/api/about/site').then(res => {
                if (res.ok) return res.json()
                throw new Error('Cannot GET site info!')
            }).then(data => {
                this.setState(this.setState({ info: data, loading: false }))
            }).catch(err => {
                this.setState({ loading: false, failed: true })
                console.error(err.message)
            })
        })
    }

    render() {
        return (
            this.state.loading ? (
                <Loader />
            ):(
                this.state.failed ? (
                    <Redirect to="/not-found" />
                ):(
                    <Container fluid >
                        <Row >
                            <Col className="mt-1">
                                <Header value="Hakkımızda" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="p-3">
                                    <p className="pb-2">{this.state.info.description || <em>Henüz bilgi girilmemiş.</em>}</p>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Social content={{...this.state.info, description: undefined}} />
                        <BoardMemberList />
                    </Container>
                )
            )
        )
    }
}

export default AboutUs
