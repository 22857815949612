import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// React Base Component
import React from 'react'

// Reactstrap Components
import {
    Col,
    Row,
} from 'reactstrap'

//Custom Components
import recaptcha from '../../helper/ReCaptcha'

import { faTimesCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons'
// import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

class ManageCommnet extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.id,
            status: this.props.status
        }

        this.getProps = () => {
            this.setState({ status: !this.state.status }, () => {
                recaptcha.getReCaptchaToken(token => {
                    fetch('/api/comment/update-status', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ...this.state, 
                            gToken: token})
                    }).then(res => {
                        if (res.ok) {
                            this.props.setStatus()
                            this.props.fetchCommentData()
    
                            return res.json()
                        }
                        else throw new Error('Can not update status of the comment!')
                    }).catch(err => {
                        console.error(err.message)
                    })
                })
            })
        }
    }

    render() {
        return (
            <Row>
                {this.state.status ? (
                    <Col className="mr-0 pr-0">
                        <FontAwesomeIcon style={{ cursor: "pointer", color: (this.state.status ? "green" : undefined) }} icon={faCheckCircle} onClick={this.getProps} />
                    </Col>
                ):(
                    <Col className="ml-0">
                        <FontAwesomeIcon style={{ cursor: "pointer", color: (this.state.status ? undefined : "red") }} icon={faTimesCircle} onClick={this.getProps} />
                    </Col>
                )}
            </Row>
        )
    }

}

export default ManageCommnet