// React Base Component
import React from 'react'

// Custom Components
import Comment from './Comment'
import Loader from '../../global/Loader'
import CommentPost from './CommentPost'
import recaptcha from '../../helper/ReCaptcha'

// CSS Module
import styles from './modules/Comment.module.css'

class CommentList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            blogID: this.props.blogId,
            comments: [],
            dummyStatus: undefined,
            loading: true
        }

        this.fetchCommentData = () => {
            this.setState({ loading: true }, () => {
                recaptcha.getReCaptchaToken(token => {
                    fetch('/api/comment', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            blogID: this.props.blogId, 
                            gToken: token
                        })
                    }).then(res => {
                        if (res.ok) return res.json()
                        throw new Error('Can not get comments of the blog!')
                    }).then(data => {
                        this.generateCommentObj(data)
                    }).catch(err => {
                        this.setState({ loading: false })
                        console.error(err.message)
                    })

                })

            })
        }

        this.generateCommentObj = async (comments) => {
            var baseCommentDict = {}
            var subCommentDict = []
            var generatedComments = []
            comments.map((baseComment) => (baseComment.replyTo == null ? baseCommentDict[baseComment.id] = { baseComment, replies: [] } : subCommentDict.push(baseComment)))
            subCommentDict.forEach((subComment) => (baseCommentDict[subComment.replyTo].replies.push(subComment)))
            Object.keys(baseCommentDict).forEach((key) => {
                generatedComments.push(baseCommentDict[key])
            })
            this.setState({ comments: generatedComments, loading: false })
        }

    }
    componentDidMount() {
        this.fetchCommentData()
    }

    render() {
        return (
            this.state.loading ? (
                <Loader />
            ) : (
                    <div>
                        {
                            this.state.comments.length === 0 ? (
                                <div>
                                    <hr style={{ borderTop: "1px solid lightgray" }} />
                                    <p className={styles.noComment}>Henüz yorum yapılmamış.</p>
                                </div>
                            ) : (
                                    this.state.comments.map((element,index) => (
                                        <Comment key={index} comment={element} fetchCommentData={this.fetchCommentData} />
                                    ))
                                )
                        } 
                        <CommentPost blogId={this.state.blogID} fetchCommentData={this.fetchCommentData}/>    
                    </div>

                )
        )
    }

}

export default CommentList