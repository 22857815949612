// React Base Component
import React from 'react'

// React Router Components
import { Link, NavLink } from 'react-router-dom'

// Reactstrap Components
import { Button, ButtonGroup, Col, Container, Nav, NavItem, Row } from 'reactstrap'

class NotFound extends React.Component {
    render() {
        return (
            <Container fluid className="h-100 d-flex align-items-center justify-content-center">
                <div>
                    <Row className="align-items-center">
                        <Col className="flex-grow-0">
                            <p style={{ fontSize: "48pt", minWidth: '3rem' }}>:(</p>
                        </Col>
                        <Col>
                            <h2>404</h2>
                            Aradığınız Sayfa bulunamadı.
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ButtonGroup>
                                <Button color="dark" className="px-0 py-0 d-flex">
                                    <Link className="text-light text-decoration-none px-3 py-1" to="/feed">
                                        Akış
                                    </Link>
                                </Button>
                                <Button color="dark" className="px-0 py-0 d-flex">
                                    <Link className="text-light text-decoration-none px-3 py-1" to="/announcements">
                                        Etkinlikler
                                    </Link>
                                </Button>
                                <Button color="dark" className="px-0 py-0 d-flex">
                                    <Link className="text-light text-decoration-none px-3 py-1" to="/contests">
                                        Yarışmalar
                                    </Link>
                                </Button>
                                <Button color="dark" className="px-0 py-0 d-flex">
                                    <Link className="text-light text-decoration-none px-3 py-1" to="/blogs">
                                        Blog
                                    </Link>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </div>
            </Container>
        )
    }
}

export default NotFound
