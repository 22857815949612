// React Base Component
import React from 'react'

// React Redux Connection
import { connect } from 'react-redux'
import { toggleUserList } from '../helper/ReduxActions'

// Reactstrap Components
import {
    Button,
    Col,
    Row
} from 'reactstrap'

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserFriends } from '@fortawesome/free-solid-svg-icons'

class Header extends React.Component {
    render() {
        return (
            <header className="w-100 pb-2 pt-4">
                <Row className="align-items-center">
                    <Col >
                        <h2 className="display-4 px-3 text-muted">{this.props.value}</h2>
                    </Col>
                    {this.props.login && !this.props.hide ? (
                        <Col xs="auto" className="ml-auto d-inline">
                            <Button color="dark" onClick={() => this.props.toggleUserList(false)}>
                                <text className="d-none d-sm-inline">Kullanıcılar</text> <FontAwesomeIcon icon={faUserFriends} />
                            </Button>
                        </Col>
                    ) : (null)}
                </Row>
                <hr />
            </header>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login
    }
}

const mapDipatchToProps = {
    toggleUserList
}

export default connect(mapStateToProps, mapDipatchToProps)(Header)
