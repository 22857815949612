// React Base Component
import React from 'react'
//Custom Components
import recaptcha from '../helper/ReCaptcha'

// Router components
import { Link } from 'react-router-dom'

// Reactstrap Components
import {
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    InputGroup,
    InputGroupText,
    FormFeedback,
    InputGroupAddon,
    CustomInput,
    Alert
} from 'reactstrap'

class RegisterForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            inputs: {
                fname: "",
                lname: "",
                uname: "",
                pwd: "",
                confPwd: "",
                chk: false
            },
            warnings: {
                password: {
                    crosscheck: false,
                    invalid: false,
                    valid: false
                },
                name: {
                    uname: false,
                    fname: false,
                    lname: false
                },
                agreement: false
            },
            alert: {
                isOpen: false,
                content: false,
                error: false
            },
        }

        this.dismissAlert = () => {
            this.setState({
                alert: {
                    isOpen: false,
                    content: false,
                    error: false
                } 
            })
        }
        
        this.handleChange = (event) => {
            const update = JSON.parse(JSON.stringify(this.state))

            if (event.target.name === "chk") {
                update.inputs.chk         = event.target.checked
                update.warnings.agreement = !event.target.checked
            } else {
                update.inputs[event.target.name] = event.target.value
            }

            this.setState(update)
        }
        
        this.handlePwdBlur = (event) => {
            const update   = JSON.parse(JSON.stringify(this.state))

            if (update.inputs.pwd.length < 6) {
                update.warnings.password.valid   = false
                update.warnings.password.invalid = true
            } else if (update.inputs.pwd !== update.inputs.confPwd) {
                update.warnings.password.valid      = false
                update.warnings.password.crosscheck = true
            } else {
                update.warnings.password.valid      = true
                update.warnings.password.invalid    = false
                update.warnings.password.crosscheck = false
            }

            this.setState(update)
        }

        this.handleSubmit = (event) => {
            event.preventDefault()

            let flag = true
            for (const key in this.state.inputs) {
                if (Object.hasOwnProperty.call(this.state.inputs, key)) {
                    flag = flag && this.state.inputs[key]
                }
            }

            if (flag) {
                recaptcha.getReCaptchaToken(token => {
                    fetch('/api/register', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            uname: this.state.inputs.uname,
                            fname: this.state.inputs.fname,
                            lname: this.state.inputs.lname,
                            pwd: this.state.inputs.pwd,
                            gToken: token
                        })
                    }).then(res => {
                        if (res.ok) {
                            this.setState({
                                inputs: {
                                    fname: "",
                                    lname: "",
                                    uname: "",
                                    pwd: "",
                                    confPwd: ""
                                },
                                alert: {
                                    content: "Kayıt işlemi başarılı, lütfen mailinizi kontrol edin.",
                                    isOpen: true,
                                    error: false
                                }
                            })
                        } else {
                            throw new Error('Register error')
                        }
                    }).catch(err => {
                        console.error(err.message)
                        this.setState({ alert: {
                            content: "Kayıt işlemi başarısız.",
                            isOpen: true,
                            error: true
                        }})
                    })

                })
            } else {
                this.setState({
                    warnings: {
                        name: {
                            uname: !this.state.inputs.uname,
                            fname: !this.state.inputs.fname,
                            lname: !this.state.inputs.lname
                        },
                        password: {
                            invalid: !this.state.inputs.pwd
                        },
                        agreement: !this.state.inputs.chk
                    }
                })
            }
        }
    }

    render() {
        return (
            <Form id="register-form" onSubmit={this.handleSubmit}>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>E-Mail</Label>
                            <InputGroup>
                                <Input invalid={this.state.warnings.name.uname} name="uname" type="text" placeholder="Örn: soyad.ad" value={this.state.inputs.uname} onChange={this.handleChange} />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>@student.atilim.edu.tr</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label>Ad</Label>
                            <Input invalid={this.state.warnings.name.fname} name="fname" type="text" placeholder="Ad" value={this.state.inputs.fname} onChange={this.handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Soyad</Label>
                            <Input invalid={this.state.warnings.name.lname} name="lname" type="text" placeholder="Soyad" value={this.state.inputs.lname} onChange={this.handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Şifre</Label>
                            <Input valid={this.state.warnings.password.valid} invalid={this.state.warnings.password.invalid} name="pwd" type="password" placeholder="Şifre" onBlur={this.handlePwdBlur} value={this.state.inputs.pwd} onChange={this.handleChange} />
                            <FormFeedback invalid>En az 6 Karakter</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Şifre Tekrar</Label>
                            <Input valid={this.state.warnings.password.valid} invalid={this.state.warnings.password.crosscheck} name="confPwd" onBlur={this.handlePwdBlur} type="password" placeholder="Şifre Tekrar" value={this.state.inputs.confPwd} onChange={this.handleChange} />
                            <FormFeedback invalid>Şifreler uyumsuz</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <CustomInput id="agreement" name="chk" label="Kullanıcı Sözleşmesini Okudum ve Kabul Ediyorum" type="checkbox" invalid={this.state.warnings.agreement} onChange={this.handleChange} />
                            <Input invalid={this.state.warnings.agreement} hidden /> {/* For form feedback */}
                            <FormFeedback invalid>* Kullanıcı sözleşmesini kabul etmediniz</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <Button color="dark" outline size="block" tag={Link} to="/login">Giriş Yap</Button>
                    </Col>
                    <Col xs="6">
                        <Button color="dark" form="register-form" size="block">Kayıt Ol</Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="pt-2" style={{ minHeight: "8rem" }}>
                        <Alert color={this.state.alert.error ? 'danger' : 'success'} isOpen={this.state.alert.isOpen} toggle={this.dismissAlert}>
                            {this.state.alert.content}
                        </Alert>
                    </Col>
                </Row>
            </Form>
        )
    }
}



export default (RegisterForm)
