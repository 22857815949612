import React from 'react'

import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';  // import as default

//Custom Components
import recaptcha from '../helper/ReCaptcha'

//React Quill CSS
import './modules/quill.snow.css'
import Loader from '../global/Loader'
Quill.register('modules/imageResize', ImageResize);

const _REF = {}

class TextEditor extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
             content: "", 
             media: "http://res.cloudinary.com/ddp3yj55b/image/upload/v1614945304/acc/ibi9rh9xwwdorh2unwys.jpg"
        }

        _REF.fileRef = React.createRef()

        this.handleChange = (html) => {
            this.setState({content: html}, () => {

                this.props.setContent(this.state.content)
            })
        }

        this.returnMedia =()=> {
            return this.state.media
        }

        this.handleImageChange = (event) => {
            this.setState({ loadingImg: true }, () => {
                const formData = new FormData()
                formData.append('img', event.target.files[0])

                recaptcha.getReCaptchaToken(token => {
                    formData.append("gToken", token)

                    fetch('/api/media/upload/image', {
                        method: 'POST',
                        body: formData
                    }).then(res => {
                        if (res.ok) return res.json()
                        throw new Error('Failed to upload image')
                    }).then(data => {
                        // Save current cursor state
                        const range = _REF.quill.getSelection(true)
                        
                        // Insert temporary loading placeholder image
                        _REF.quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`)
                        
                        // Move cursor to right side of image (easier to continue typing)
                        _REF.quill.setSelection(range.index + 1)
                        
                        // const res = await apiPostNewsImage(formData) // API post, returns image location as string e.g. 'http://www.example.com/images/foo.png'
                        
                        // Remove placeholder image
                        _REF.quill.deleteText(range.index, 1)
                        
                        // Insert uploaded image
                        // this.quill.insertEmbed(range.index, 'image', res.body.image)
                        _REF.quill.insertEmbed(range.index, 'image', data.src)
                    }).catch(err => {
                        console.error(err.message)
                    }).finally(() => {
                        this.setState({ loadingImg: false })
                    })
                })
            })
        }
    }

    imageHandler() {
        if (this && !_REF.quill) {
            _REF.quill = this.quill
        }

        _REF.fileRef.current.click()
    }

    render() {
        const media =  this.state.media

        return(
            <div>
                <input ref={_REF.fileRef} type="file" onChange={this.handleImageChange} hidden />
                {this.state.loadingImg ? (
                    <div>
                        <Loader />
                        <em className="pb-2">Resim Yükleniyor...</em>
                    </div>
                ):(null)}
                <ReactQuill
                    onChange={this.handleChange}
                    value={this.state.content || this.props.content}
                    placeholder={"İçeriği buraya yazınız..."}
                    modules = {{
                        imageResize: {
                            parchment: Quill.import('parchment'),
                            modules: [ 'Resize', 'DisplaySize']
                            // See optional "config" below
                        },
                        toolbar: {
                            container: [
                                [{ 'font': [] }],
                                [{size: []}],
                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                [{'list': 'ordered'}, {'list': 'bullet'}],
                                ['link', 'image', 'video'],
                                ['clean'],
                                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                [{ 'align': [] }],
                                ['code-block'],
                                ['undo']
                            ],
                            handlers: {
                                image: this.imageHandler
                            }, 

                        },
                        clipboard: {
                            // toggle to add extra line breaks when pasting HTML:
                            matchVisual: false,
                        }
                    }}
                    formats = {TextEditor.formats}
                />
            </div>
        )
    }
}

// TextEditor.modules = {

//     toolbar: [
//         [{ 'font': [] }],
//         [{size: []}],
//         ['bold', 'italic', 'underline', 'strike', 'blockquote'],
//         [{'list': 'ordered'}, {'list': 'bullet'}],
//         ['link', 'image', 'video'],
//         ['clean'],
//         [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
//         [{ 'align': [] }],
//         ['code-block'],
//         ['undo']
//       ],
//       handlers: {
//         image: this.imageHandler
//     },
//       clipboard: {
//         // toggle to add extra line breaks when pasting HTML:
//         matchVisual: false,
//       }
// }

TextEditor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video', 'undo', 'color', 'background', 'align', 'width', 'code-block'
  ]

export default TextEditor