// React Base Component
import React from 'react'

// React Redux Connection
import { connect } from 'react-redux'

// Reactstrap Components
import {
    Col,
    Row
} from 'reactstrap'

import { Link } from 'react-router-dom'

//Custom Components
import ManageComment from './ManageComment'
import CommentReply from './CommentReply'
import ReplyList from './ReplyList'

//CSS Module
import styles from './modules/Comment.module.css'

class Comment extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            commentObj: this.props.comment,
            status: this.props.comment.baseComment.status
        }

        /**
         * Format datestring to TR locale
         * @param {string} date The ISO-Date string 
         */
        this.formatDate = (date = "") => {
            return (new Date(date)).toLocaleDateString('tr-TR')
        }

        /**
         * Format datestring to ss:mm:hh
         * @param {string} date The ISO-Date string 
         */
        this.formatHour = (date = "") => {
            return (new Date(date)).toTimeString().split(' ')[0].slice(0, 5)
        }

        this.generateReactionObj = (includesMyReaction, reaction1, reaction2, reaction3, reaction4) => {
            const reactionObj = {
                includesMyReaction,
                reaction1,
                reaction2,
                reaction3,
                reaction4
            }
            return reactionObj
        }

        this.setStatus = () => {
            this.setState({ status: !this.state.status })
        }
    }
    
    render() {
        return (
            <div>
                <hr />
                <Row>
                    <Col xs="auto" className="pr-1">
                        <Link to={"/profile/" + this.state.commentObj.baseComment.uname} >
                            <div className={styles.Avatar} style={{ minWidth: '60px', backgroundImage: this.state.commentObj.baseComment.avatar ? `url('${this.state.commentObj.baseComment.avatar}')` : `url('logo512.png')` }} />
                        </Link>
                    </Col>
                    <Col xs="auto">
                        <Row>

                        </Row>
                        <Row>
                            <Col xs="auto" className="pl-0">
                                <Link className="text-decoration-none" to={"/profile/" + this.state.commentObj.baseComment.uname} >
                                    <p className={styles.userNameStyle}>{this.state.commentObj.baseComment.name + " "}</p>
                                </Link>
                                <p className={styles.inline}>dedi ki:</p>
                            </Col>
                            <Col>
                                {this.props.role >= 1 ? <ManageComment fetchCommentData={this.props.fetchCommentData} id={this.state.commentObj.baseComment.id} name={this.state.commentObj.baseComment.name}
                                    content={this.state.commentObj.baseComment.content}
                                    status={this.state.commentObj.baseComment.status}
                                    setStatus={this.setStatus}
                                /> : undefined}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="auto" className="pl-0">
                                <p className={styles.datesOfComments}>{this.formatDate(this.state.commentObj.baseComment.date) + " "}</p>
                                <p className={styles.datesOfComments}>{this.formatHour(this.state.commentObj.baseComment.date)}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {
                    this.state.status ? (
                        <div>
                            <Row>
                                <Col xs="8">
                                    <p>{this.state.commentObj.baseComment.content}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="10" className="pb-2">
                                    <CommentReply
                                        blogID={this.state.commentObj.baseComment.blogId} id={this.state.commentObj.baseComment.id} reactionObj={this.generateReactionObj(
                                            this.state.commentObj.baseComment.includesMyReaction,
                                            this.state.commentObj.baseComment.reaction1num,
                                            this.state.commentObj.baseComment.reaction2num,
                                            this.state.commentObj.baseComment.reaction3num,
                                            this.state.commentObj.baseComment.reaction4num)}
                                        fetchCommentData={this.props.fetchCommentData} />
                                </Col>
                            </Row>

                        </div>
                    ) : (
                        <p className={styles.noComment}>Bu yorum kısıtlanmış.</p>
                    )
                }
                <Row>
                    <Col xs="10" className="py-0">
                        {this.state.commentObj.replies.length > 0 ? <ReplyList fetchCommentData={this.props.fetchCommentData} replies={this.state.commentObj.replies} /> : undefined}
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        name: state.name,
        role: state.role
    }
}

const mapDipatchToProps = {
    // empty
}

export default connect(mapStateToProps, mapDipatchToProps)(Comment)