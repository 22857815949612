function getReCaptchaToken(callback) {
    console.log("get gToken")

    if (window.grecaptcha) {
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute('6LdV6uIZAAAAAGWgIFsDrqb1NVFLZyUM3wOc-S9o', { action: 'submit' }).then((token) => {
                callback(token)
            })
        })
    }
}

export default {
    getReCaptchaToken
}
