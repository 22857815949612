// React Base Component
import React from 'react'

// Reduc Connection
import { connect } from 'react-redux'

// React Router Components
import { Redirect, Link } from 'react-router-dom'

// Reactstrap Components
import {
    Container,
    Row,
    Col,
    Label,
} from 'reactstrap'

// Custom Components
import Avatar from './Avatar'
import Header from '../global/Header'
import Loader from '../global/Loader'
import Social from '../global/Social'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments, faThumbsUp, faBlog } from '@fortawesome/free-solid-svg-icons'
import { faBlogger } from '@fortawesome/free-brands-svg-icons'

class Profile extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            loading: true,
            failed: false,
            blogList: [],
            loadingBlogList: true,
        }

        this.fetchProfileData = () => {
            this.setState({ loading: true }, () => {
                fetch('/api/profile' + (this.props.pathname !== "/profile" ? this.props.pathname : "")).then(res => {
                    if (res.ok) return res.json()
                    throw new Error('Cannot GET profile!')
                }).then(data => {
                    this.setState({ data, loading: false })
                }).catch(err => {
                    this.setState({ loading: false, failed: true })
                    console.error(err.message)
                })
            })
        }

        this.fetchBlogData = async () => {
            fetch('/api/profile/blog/' + (this.props.pathname !== "/profile" ? this.props.pathname : ""), {
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(res => {
                if (res.ok) return res.json()
                throw new Error('Can not get blogs!')
            }).then(data => {
                this.setState({ blogList: data, loadingBlogList: false })
            }).catch(err => {
                console.error(err.message)
            })
        }
    }
    
    componentDidMount() {
        this.fetchProfileData()
        this.fetchBlogData()
    }

    componentDidUpdate(oldProps) {
        if (oldProps.pathname !== this.props.pathname) {
            this.fetchProfileData()
            this.fetchBlogData()
        }
    }

    render() {
        return (
            this.state.loading ? (
                <Loader />
            ):(
                this.state.failed ? (
                    <Redirect to="/not-found" />
                ):(
                    <Container fluid>
                        <Row>
                            <Col>
                                <Header value="Profil" />
                            </Col>
                        </Row>
                        <Row className="pb-3">
                            <Col xs="4">
                                <Avatar own={this.props.own} data={this.state.data} fetchProfileData={this.fetchProfileData} />
                            </Col>
                            <Col className="pt-4">
                                <h4>{this.state.data.name}</h4>
                                <h6 className="text-muted">
                                    {this.state.data.mail}
                                </h6>
                                <div className="pt-3">
                                    {this.state.data.description || <em className="text-muted">Özet bilgi yok.</em>}
                                </div>
                                <div className="pt-5">
                                    <span className="d-flex justify-content-end text-dark">
                                        <h4>
                                            <FontAwesomeIcon icon={faBlog} className="mx-1"/>{this.state.data.blogNum}
                                        </h4>
                                        <h4 className="mx-3">
                                            <FontAwesomeIcon icon={faComments} className="mx-1"/>{this.state.data.commentNum}
                                        </h4>
                                        <h4>
                                            <FontAwesomeIcon icon={faThumbsUp} className="mx-1"/>{this.state.data.reactionNum}
                                        </h4>
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Social own={this.props.own} content={this.state.data.social} />
                            </Col>
                        </Row>
                        <div>
                           <Label style={{fontSize: "17px"}} className="mt-5 mb-0 font-weight-bold">{this.state.data.name} şu blogları yayınladı:</Label>
                           <p className="text-muted font-italic pb-3" style={{fontSize: "13px"}} >Bloglara gitmek için başlıklara tıklayın</p> 
                        </div>
                        {   
                            this.state.loadingBlogList ? (
                                <div>
                                    <Loader />
                                    Blog listesi yükleniyor...
                                </div>
                                
                            ) : (
                                this.state.blogList.length ? (
                                    <ul style={{ listStyleType: "square" }}>
                                        {
                                            this.state.blogList.map(element => (
                                                <li>
                                                    <Row>
                                                        <Col>
                                                        <Link  to={"/blogs" + element.url} className="text-dark"><h5>{element.title}</h5></Link>
                                                            
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            {
                                                                element.summary ? (
                                                                    <div >
                                                                        <p className="pr-1 text-muted font-italic mx-0" style={{display: "inline"}}>Özet bilgi:</p>
                                                                        <p className="text-muted" style={{display: "inline"}}>{element.summary}</p>
                                                                    </div>
                                                                ) : (
                                                                    <small className="text-muted font-italic" style={{fontSize: "13px"}}>Özet bilgi paylaşılmamış</small>
                                                                )
                                                            }
                                                        </Col>
                                                    </Row>
                                                    
                                                </li>
                                            ))
                                        }
                                    </ul>

                                ) : (
                                    <p className="text-muted font-italic">Bu kişi henüz blog paylaşmamış.</p>
                                )
                            )
                        }
                    </Container>
                )
            )
        )
    }
}

export default Profile

