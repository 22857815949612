// React Base Component
import React from 'react'

// React Router Components
import { Link } from 'react-router-dom'

// Reactstrap Components
import {
    Button,
    Col,
    Container,
    CustomInput,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    PopoverBody,
    PopoverHeader,
    Row,
    UncontrolledPopover
} from 'reactstrap'

// Custom Components
import Loader from '../global/Loader'
import recaptcha from '../helper/ReCaptcha'

// Custom Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCaretDown,
    faSearch
} from '@fortawesome/free-solid-svg-icons'

// CSS Module
import styles from './modules/Media.module.css'

class Filter extends Form {
    constructor(props) {
        super(props)

        this.state = {
            search: "",
            active: true,
            passive: true,
            none: true,
            blog: true,
            announcement: true,
            image: true,
            avatar: true,
            change: false
        }

        this.handleChange = (event) => {
            const update = { ...this.state }
            update[event.target.name] = (event.target.name === 'search' ? event.target.value : event.target.checked)
            update.change = true
            this.setState(update)
        }
    }

    componentDidUpdate() {
        if (this.state.change) {
            this.setState({ change: false }, () => {
                this.props.update({ ...this.state })
            })
        }
    }

    render() {
        return (
            <Form className="py-3" onSubmit={(event) => event.preventDefault()}>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <Button color="dark" id="filters" type="button" block>
                            Filtreler <FontAwesomeIcon icon={faCaretDown} />
                        </Button>
                        <UncontrolledPopover trigger="legacy" placement="bottom" isOpen={this.state.isOpen} target="filters" toggle={() => this.setState({ isOpen: !this.state.isOpen })}>
                            <PopoverHeader>Filtreler</PopoverHeader>
                            <PopoverBody>
                                <FormGroup>
                                    <Label>Durum</Label>
                                    <div>
                                        <CustomInput type="checkbox" name="active" id="active" label={"Aktif"} checked={this.state.active} onChange={this.handleChange} />
                                        <CustomInput type="checkbox" name="passive" id="passive" label={"Pasif"} checked={this.state.passive} onChange={this.handleChange} />
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Paylaşım</Label>
                                    <div>
                                        <CustomInput type="checkbox" name="none" id="none" label={"Atanmamış"} checked={this.state.none} onChange={this.handleChange} />
                                        <CustomInput type="checkbox" name="blog" id="blog" label={"Blog"} checked={this.state.blog} onChange={this.handleChange} />
                                        <CustomInput type="checkbox" name="announcement" id="announcement" label={"Duyuru"} checked={this.state.announcement} onChange={this.handleChange} />
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Durum</Label>
                                    <div>
                                        <CustomInput type="checkbox" name="image" id="image" label={"Resim"} checked={this.state.image} onChange={this.handleChange} />
                                        <CustomInput type="checkbox" name="avatar" id="avatar" label={"Avatar"} checked={this.state.avatar} onChange={this.handleChange} />
                                    </div>
                                </FormGroup>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </InputGroupAddon>
                    <Input type="text" name="search" placeholder="Hızlı Arama" value={this.state.search} onChange={this.handleChange} />
                    <InputGroupAddon addonType="append">
                        <Button color="dark">
                            <FontAwesomeIcon icon={faSearch} />
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </Form>
        )
    }
}

class MediaEdit extends Modal {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            loading: false
        }

        this.handleChange = (event) => {
            const update = { ...this.state }
            update.change = true
            update.data[event.target.name] = event.target.name === 'status' ? event.target.checked : event.target.value
            this.setState(update)
        }

        this.handleRemove = () => {
            if (this.state.remove) {
                this.setState({ loading: true }, () => {
                    recaptcha.getReCaptchaToken(token => {
                        fetch('/api/manage/media/remove', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                src: this.state.data.src,
                                gToken: token
                            })
                        }).then(res => {
                            if (res.ok) {
                                this.props.remove()
                            } else {
                                throw new Error('Failed to remove media.')
                            }
                        }).catch(err => {
                            console.error(err.message)
                        }).finally(() => {
                            this.setState({ loading: false })
                        })
                    })
                })
            } else {
                this.setState({ remove: true })
            }
        }

        this.handleSubmit = () => {
            recaptcha.getReCaptchaToken(token => {
                fetch('/api/manage/media', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        status: this.state.data.status,
                        alt: this.state.data.alt,
                        src: this.state.data.src,
                        gToken: token
                    })
                }).then(res => {
                    if (res.ok) {
                        this.props.save()
                    } else {
                        throw new Error('Failed to save changes.')
                    }
                }).catch(err => {
                    console.error(err.message)
                })
            })
        }

        this.renewData = () => {

            this.setState({ data: { ...this.props.data }, remove: false })
        }
    }

    componentDidMount() {
        this.renewData()
    }

    componentDidUpdate(oldProps) {
        if (this.props.data.src && ((oldProps.data.src !== this.props.data.src) || (oldProps.isOpen === false && this.props.isOpen === true))) {
            this.renewData()
        }
    }

    render() {
        return (
            <Modal {...this.props} scrollable>
                <ModalHeader>Medya Seçenekleri</ModalHeader>
                <ModalBody>
                    {this.state.loading ? (
                        <Loader />
                    ) : (
                        <Form>
                            <FormGroup>
                                <Label>Durum</Label>
                                <CustomInput type="switch" name="status" id="status" label={this.state.data.status ? "Aktif" : "Pasif"} checked={this.state.data.status} onChange={this.handleChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Açıklama</Label>
                                <Input type="text" name="alt" value={this.state.data.alt} onChange={this.handleChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label>Yükleyen</Label>
                                <Input type="text" value={this.state.data.uploader} disabled readOnly />
                            </FormGroup>
                            <FormGroup>
                                <Label>Adres</Label>
                                <Input type="text" value={this.state.data.src} disabled readOnly />
                            </FormGroup>
                            <FormGroup>
                                <Label>Bloglar</Label>
                                <ul style={{ listStyleType: "square" }}>
                                    {(this.state.data.blogs && typeof this.state.data.blogs === "string") ? this.state.data.blogs.split(',').map(item => (
                                        <li><Link to={"/blogs" + item}>{item}</Link></li>
                                    )) : (
                                        <li><em>Paylaşılmamış</em></li>
                                    )}
                                </ul>
                            </FormGroup>
                            <FormGroup>
                                <Label>Etkinlikler</Label>
                                <ul style={{ listStyleType: "square" }}>
                                    {(this.state.data.announcements && typeof this.state.data.announcements === "string") ? this.state.data.announcements.split(',').map(item => (
                                        <li><Link to={"/announcements" + item}>{item}</Link></li>
                                    )) : (
                                        <li><em>Paylaşılmamış</em></li>
                                    )}
                                </ul>
                            </FormGroup>
                        </Form>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" className="mr-auto" onClick={this.handleRemove}>
                        {this.state.remove ? "Onaylamak İçin Tekrar Basın" : "Kaldır"}
                    </Button>
                    <Button color="dark" outline onClick={this.props.toggle}>
                        İptal
                    </Button>
                    <Button color="dark" onClick={this.handleSubmit}>
                        Kaydet
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

class MediaItem extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            height: 0
        }

        this.reference = React.createRef()

        this.iso2date = (str) => {
            return (new Date(str)).toLocaleDateString("tr")
        }

        this.iso2time = (str) => {
            return (new Date(str)).toLocaleTimeString("tr")
        }
    }

    componentDidMount() {
        this.setState({ height: this.reference.current.offsetWidth * 0.5625 })
    }

    render() {
        return (
            <Col xs="12" md="6" xl="4" className="mb-3">
                <div ref={this.reference} style={{ height: this.state.height }}>
                    <div className={styles.MediaItem} style={{ backgroundImage: `url(${this.props.data.src})` }} onClick={() => this.props.edit(this.props.data.src)}>
                        <div className={styles.MediaItemContent}>
                            <div>
                                {this.props.data.alt}
                            </div>
                            <div>
                                <small>{this.iso2date(this.props.data.date)} - {this.iso2time(this.props.data.date)}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        )
    }
}

class Media extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            filtered: [],
            editTarget: {},
            editorOpen: false,
            loading: false,
            failed: false,
            failMessage: ""
        }

        this.filter = async (filters) => {
            const filtered = this.state.data.filter((element, index) => {
                const regexp = new RegExp(filters.search.replace(/(^\s+)|(\s+$)/g, "").replace(/\s+/g, " "), "ig")
                const search = !filters.search || [element.src, element.alt, element.uploader, element.alt].join('|').match(regexp) ? true : false

                const active = filters.active ? (element.status === 1 ? true : false) : false
                const passive = filters.passive ? (element.status === 0 ? true : false) : false

                const none = filters.none ? !(element.blogs || element.announcements) ? true : false : false
                const blog = filters.blog ? (element.blogs ? true : false) : false
                const announcement = filters.announcement ? (element.announcements ? true : false) : false

                const image = filters.image ? (element.type == 0 ? true : false) : false
                const avatar = filters.avatar ? (element.type == 1 ? true : false) : false

                return search && (active || passive) && (blog || announcement || none) && (image || avatar)
            })

            this.setState({ filtered })
        }

        this.edit = async (src) => {
            const index = this.state.data.findIndex(element => element.src === src)

            if (index !== -1) {
                this.setState({ editTarget: this.state.data[index], editorOpen: true })
            }
        }

        this.save = () => {
            this.fetchMediaTable()
            this.toggle()
        }

        this.remove = () => {
            this.fetchMediaTable()
            this.toggle()
        }

        this.toggle = () => {
            this.setState({ editorOpen: !this.state.editorOpen })
        }

        this.fetchMediaTable = () => {
            this.setState({ loading: true }, () => {
                fetch('/api/manage/media').then(res => {
                    if (res.ok) return res.json()
                    throw new Error("Cannot GET /api/manage/media")
                }).then(data => {
                    this.setState({ data, filtered: JSON.parse(JSON.stringify(data)), loading: false })
                }).catch(err => {
                    this.setState({ loading: false, failed: true, failMessage: err.message })
                    console.error(err.message)
                })
            })
        }
    }

    componentDidMount() {
        this.fetchMediaTable()
    }

    render() {
        return (
            <Container fluid>
                <MediaEdit save={this.save} remove={this.remove} isOpen={this.state.editorOpen} data={this.state.editTarget} toggle={this.toggle} />
                <Row>
                    <Col>
                        <Filter update={this.filter} />
                    </Col>
                </Row>
                <Row>
                    {this.state.loading ? <Loader /> : (
                        this.state.failed ? this.state.failMessage : (
                            this.state.filtered.map((element, index) => (
                                <MediaItem key={index} data={element} edit={this.edit} />
                            ))
                        )
                    )}
                </Row>
            </Container>
        )
    }
}

export default Media
export {
    MediaItem,
    Filter
}