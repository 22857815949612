// React Base Component
import React from 'react'

// React Router Components
import { Link } from 'react-router-dom'

class Logo extends React.Component {
    render() {
        return (
            <Link className={["d-block", "w-" + Math.round(parseFloat(this.props.width) / 25) * 25].join(" ")} to="/">
                <img className="w-100 img-fluid" src={process.env.PUBLIC_URL + "/logo.svg"} alt="logo" />
            </Link>
        )
    }
}

export default Logo
