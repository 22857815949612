// React Base Component
import React from 'react'

// Redux store connection
import { connect } from 'react-redux'
import { loginHandle, toggleUserList } from './helper/ReduxActions'

// React Router Components
import {
    BrowserRouter,
    Switch,
    Redirect
} from 'react-router-dom'

// Custom Components
import MainLayout from './global/MainLayout'
import ReduxRoute from './global/ReduxRoute'
import Login from './user/Login'
import Logout from './user/Logout'
import BlogList from './blog/BlogList'
import Blog from './blog/Blog'
import Announcement from './announcement/Announcement'
import AnnouncementList from './announcement/AnnouncementList'
import Profile from './profile/Profile'
import NotFound from './global/NotFound'
import ManageBlog from './manage/Blog'
import ManageMedia from './manage/Media'
import ManageUser from './manage/User'
import ManageAnnouncement from './manage/Announcement'
import ManageSettings from './manage/Settings'
import AboutUs from './aboutUs/AboutUs'
import NewBlog from '../component/textEditor/NewBlog'
import NewAnncouncement from '../component/textEditor/NewAnncouncement'
import Register from './register/Register'
import ResetPassword from './user/ResetPassword'
import VerifyAccount from './register/VerifyAccount'
import LandingPage from './home/LandingPage'

class Router extends React.Component {    
    componentDidMount() {
        fetch('/api/login').then(res => {
            if (res.ok) {
                res.text().then(token => {
                    this.props.loginHandle({ login: true, token })
                })
            } else {
                this.props.loginHandle({ login: false, token: "", role: 0, name: "" })
                console.error("Invalid session!")
            }
        })
    }

    componentDidUpdate(oldProps) {
        if (oldProps.pathname !== this.props.pathname) {
            this.props.toggleUserList(true)
        }
    }
    
    render() {
        return (
            <BrowserRouter {...this.props}>
                <Switch>
                    <ReduxRoute exact path="/">
                        <Redirect to="/home" />
                    </ReduxRoute>
                    <ReduxRoute exact path="/home">
                        <MainLayout page={<LandingPage />}/>
                    </ReduxRoute>
                    <ReduxRoute exact path="/login">
                        {this.props.login ? <Redirect to="/profile" /> : <Login />}
                    </ReduxRoute>
                    <ReduxRoute exact path="/register">
                        <Register/>
                    </ReduxRoute>
                    <ReduxRoute path="/announcements" render={({ match, location }) => (
                        <MainLayout page={match.isExact ? <AnnouncementList /> : <Announcement pathname={location.pathname.substr(location.pathname.lastIndexOf('/'))} />} />
                    )} />
                    <ReduxRoute path="/blogs/category/*" render={({ location }) => (
                        <MainLayout page={ <BlogList category={location.pathname.substr(location.pathname.lastIndexOf('/'))}/> }/>
                    )} />
                    <ReduxRoute path="/blogs" render={({ match, location }) => (
                        <MainLayout page={match.isExact ? <BlogList /> : (<Blog pathname={location.pathname.substr(location.pathname.lastIndexOf('/'))}/>)} />
                    )} />
                    <ReduxRoute exact path="/about-us">
                        <MainLayout page={<AboutUs/>} />
                    </ReduxRoute>
                    <ReduxRoute path="/profile" render={({ match, location }) => (
                        !this.props.login ? <Redirect to="/" /> : <MainLayout page={<Profile own={match.isExact} pathname={location.pathname.substr(location.pathname.lastIndexOf('/'))} />} />
                    )} />
                    <ReduxRoute path="/manage/blog">
                        <MainLayout page={<ManageBlog />} />
                    </ReduxRoute>
                    <ReduxRoute path="/manage/media">
                        <MainLayout page={<ManageMedia />} />
                    </ReduxRoute>
                    <ReduxRoute path="/manage/user">
                        <MainLayout page={<ManageUser />} />
                    </ReduxRoute>
                    <ReduxRoute path="/manage/announcement">
                        <MainLayout page={<ManageAnnouncement />} />
                    </ReduxRoute>
                    <ReduxRoute path="/settings">
                        <MainLayout page={<ManageSettings />} />
                    </ReduxRoute>
                    <ReduxRoute path="/new-blog">
                        <MainLayout page={<NewBlog />} />
                    </ReduxRoute>
                    <ReduxRoute path="/new-annc">
                        <MainLayout page={<NewAnncouncement />} />
                    </ReduxRoute>
                    <ReduxRoute exact path="/logout">
                        {this.props.login ? <Logout /> : <Redirect to="/" />}
                    </ReduxRoute>
                    <ReduxRoute exact path="/reset-password">
                        <ResetPassword/>
                    </ReduxRoute>
                    <ReduxRoute exact path="/verify-account"render={({ location }) => (
                         <VerifyAccount pathname={location.pathname}/>
                    )}/>
                    <ReduxRoute path="*">
                        <MainLayout page={<NotFound />} />
                    </ReduxRoute>
                </Switch>
            </BrowserRouter>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login ? 1 : 0,
        pathname: state.pathname
    }
}

const mapDipatchToProps = {
    loginHandle,
    toggleUserList
}

export default connect(mapStateToProps, mapDipatchToProps)(Router)
