// React Base Component
import React from 'react'

// React Router Components
import {
    Redirect
} from 'react-router-dom'

// Reactstrap Components
import {
    Col,
    Row,
    Button,
    Container
} from 'reactstrap'
import CoverImage from '../global/CoverImage'

// Custom Components
import Loader from '../global/Loader'

class Summary extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            content: this.props.content
        }
    }

    render() {
        return(
            <Row>
                <Col className="pt-5">
                    <h3 className="text-muted font-italic">Özet bilgisi:</h3>
                    <hr/>
                    {
                        this.state.content ? (
                            <p className="font-italic">{this.state.content}</p>
                        ) : (
                            <p className="text-muted font-italic">Özet bilgi paylaşılmamış</p>
                        )
                    }
                </Col>
            </Row>
            )
    }
}

class Announcement extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            loading: true,
            failed: false
        }

        /**
         * Format datestring to TR locale
         * @param {string} date The ISO-Date string 
         */
        this.formatDate = (date = "") => {
            return (new Date(date)).toLocaleDateString('tr-TR')
        }
        
        /**
         * Format datestring to ss:mm:hh
         * @param {string} date The ISO-Date string 
         */
        this.formatHour = (date = "") => {
            return (new Date(date)).toTimeString().split(' ')[0]
        }
    }

    componentDidMount() {
        this.setState({ loading: true }, () => {
            if (this.props.pathname) {
                const target = this.props.pathname.substring(this.props.pathname.lastIndexOf('/') + 1)
    
                fetch('/api/announcement/' + target).then(res => {
                    if (res.ok) return res.json()
                    throw new Error('Cannot fetch announcements!')
                }).then(data => {

                    this.setState({ data, loading: false })
                }).catch(err => {
                    this.setState({ loading: false, failed: true })
                    console.error(err.message)
                })
            } else if(this.props.preview) {
                this.setState({data: this.props.data, loading: false}) 
            } else if(this.props.manage) {
                this.setState({loading: true}, () => {
                    fetch('/api/manage/announcement/annc-data/' + this.props.url).then(res => {

                        if (res.ok) return res.json()
                        throw new Error('Cannot fetch announcement!')
                    }).then(data => {
                        this.setState({data: data, loading: false}, () => {
                            this.props.setAnncData(data)
                        })
                    }).catch(err => {
                        console.error(err.message)
                    })
                })
            }
            
        })
    }
    
    render() {
        return (
            this.state.loading ? (
                <Loader />
            ):(
                this.state.failed ? (
                    <Redirect to="/not-found" />
                ):(
                    <Container fluid>
                        <Row>
                            <Col>
                                <h2 className="text-center d-block py-3">{this.state.data.title}</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <CoverImage src={this.state.data.media || "/logo512.png"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h5 className="pt-3 pb-1 my-0">{this.state.data.author}</h5>
                                <div className="pb-3">{(this.state.data.date ? new Date(this.state.data.date) : new Date()).toLocaleDateString()}</div>
                                <div dangerouslySetInnerHTML={{__html: this.state.data.content}} />
                            </Col>
                        </Row>
                        {
                                this.props.manage ? 
                                    <Summary content={this.state.data.summary}/>
                                 : null
                            }
                        {
                                this.props.preview ? (
                                    <Row>
                                        <Col xs="auto" className="ml-auto">
                                            <Button outline color="dark" onClick={() => this.props.setPreview()}>Düzenlemeye geri Dön</Button>
                                        </Col>
                                    </Row>       
            
                                ) : null
                            }
                    </Container>
                )
            )
        )
    }
}

export default Announcement
