// React Base Component
import React from 'react'

// React Redux Connection
import { connect } from 'react-redux'

// React Router Components
import {
    Link
} from 'react-router-dom'

// Reactstrap Components
import {
    Col,
    Row,
    Container,
    Button
} from 'reactstrap'
import CoverImage from '../global/CoverImage'

// Custom Components
import CommentList from './comment/CommentList'
import Loader from '../global/Loader'

// CSS Module
import styles from './modules/Blog.module.css'

//React Quill CSS
import '../textEditor/modules/quill.snow.css'

class Summary extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            content: this.props.content
        }
    }

    render() {
        return (
            <Row>
                <Col className="pt-5">
                    <h3 className="text-muted font-italic">Özet bilgisi:</h3>
                    <hr />
                    {
                        this.state.content ? (
                            <p className="font-italic">{this.state.content}</p>
                        ) : (
                            <p className="text-muted font-italic">Özet bilgi paylaşılmamış</p>
                        )
                    }
                </Col>
            </Row>
        )
    }
}

class Blog extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            data: {},
            callCommentComponent: false,
            fetch: false
        }

        /**
         * Format datestring to TR locale
         * @param {string} date The ISO-Date string 
         */
        this.formatDate = (date = "") => {
            return (new Date(date)).toLocaleDateString('tr-TR')
        }

        /**
         * Format datestring to ss:mm:hh
         * @param {string} date The ISO-Date string 
         */
        this.formatHour = (date = "") => {
            return (new Date(date)).toTimeString().split(' ')[0]
        }

        this.setCommentComponentState = () => {
            this.setState({ callCommentComponent: true })
        }

    }

    componentDidMount() {

        if (this.props.pathname && !this.state.fetch) {                                                 //Basic call of the blog with url
            const target = this.props.pathname.substring(this.props.pathname.lastIndexOf('/') + 1)

            fetch('/api/blog/' + target).then(res => {
                if (res.ok) return res.json()
                throw new Error('Cannot fetch the blog!')
            }).then(data => {

                if (data.categories) { data.categories = data.categories.split(',') }
                this.setState({ data, fetch: true, callCommentComponent: true, loading: false })
            }).catch(err => {
                console.error(err.message)
            })
        } else if (this.props.preview) {                                                                //Calling the component from new post component to preview
            this.setState({ data: this.props.data, loading: false })
        } else if (this.props.manage) {                                                                 //Calling of the component from editing part which requires new request that does'nt depend on the blog status
            this.setState({ loading: true }, () => {
                fetch('/api/manage/blog/blog-data' + this.props.url).then(res => {
                    if (res.ok) return res.json()
                    throw new Error('Cannot fetch the blog data!')
                }).then(data => {

                    this.setState({ data: data, loading: false }, () => {
                        this.props.setBlogData(this.state.data)
                    })
                }).catch(err => {
                    console.error(err.message)
                })
            })
        }

    }

    render() {
        return (
            this.state.loading ? (
                <Loader />
            ) : (
                <Container xl="true">
                    <Row>
                        <Col>
                            <h2 className="text-center d-block py-3">{this.state.data.title || 'Title'}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CoverImage src={this.state.data.media || "/logo512.png"} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.state.data.categories ? (
                                <div>
                                    <p className={styles.inline + " font-italic font-weight-bold"}>Kategoriler:</p>
                                    {this.state.data.categories.map((category) => (<Link to={"/blogs/category/" + category.toLowerCase()}> {category} </Link>))}
                                </div>) : (undefined)}
                            <h5 className="pt-3 pb-1 my-0">{this.state.data.author}</h5>
                            <div className="pb-3">{(this.state.data.date ? new Date(this.state.data.date) : new Date()).toLocaleDateString()}</div>
                            <div className="ql-editor" dangerouslySetInnerHTML={{ __html: this.state.data.content || 'Content' }}></div>
                        </Col>
                    </Row>
                    {
                        this.props.manage ?
                            <Summary content={this.state.data.summary} />
                            : null
                    }
                    {
                        this.props.login ? (
                            this.state.callCommentComponent ? (
                                <div>
                                    <CommentList blogId={this.state.data.id} />
                                </div>
                            ) : (undefined)
                        ) : (<div>
                            <hr style={{ borderTop: "1px solid lightgray" }} />
                            <p className={styles.unvisibleComment}>Yorumları görmek için lütfen {<Link to="/login" className={styles.unvisibleComment}>giriş</Link>} yapınız.</p>
                        </div>)

                    }
                    {
                        this.props.preview ? (
                            <Row>
                                <Col xs="auto" className="ml-auto">
                                    <Button outline color="dark" onClick={() => this.props.setPreview()}>Düzenlemeye geri Dön</Button>
                                </Col>
                            </Row>

                        ) : null
                    }
                </Container>
            )
        )
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login ? 1 : 0,
        name: state.name,
        role: state.role
    }
}

const mapDipatchToProps = {
    // empty
}

export default connect(mapStateToProps, mapDipatchToProps)(Blog)
