// React base component
import React from 'react'

// Redux store connection
import { connect } from 'react-redux'
import { updateCurrentURL, setLayoutScale } from '../helper/ReduxActions'

// Reactstrap components
import {
    Col,
    Container,
    Row
} from 'reactstrap'

// Custom components
import SidePanel from './SidePanel'
import UserList from './UserList'

// CSS Module
import styles from './modules/MainLayout.module.css'

class MainLayout extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            userList: {
                online: [],
                offline: []
            }
        }

        this.styles = {
            bgDark: {
                backgroundColor: this.props.theme.dark
            }
        }

        this.handleRef = (current) => {
            if (current) {
                props.setLayoutScale(current.clientWidth, current.clientHeight)
            }
        }
    }

    render() {
        return (
            <Row className="h-100" noGutters>
                <Col xs="12" xl="3" style={this.styles.bgDark}>
                    <SidePanel />
                </Col>
                <Col xs="12" xl="9" className="h-100 flex-grow-1">
                    <div ref={this.handleRef} className="h-100" style={{ overflowX: "hidden", overflowY: "auto" }}>
                        {
                            this.props.pathname === '/home' ? (
                                this.props.page
                            ):(
                                <Container fluid={(this.props.pathname && this.props.pathname.includes("/manage")) || "xl"}>
                                    {this.props.page}
                                </Container>
                            )
                        }
                    </div>
                </Col>
                <UserList isOpen={this.props.userList} />
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        pathname: state.pathname,
        theme: state.theme,
        login: state.login ? 1 : 0,
        width: state.layoutScale.width,
        height: state.layoutScale.height
    }
}

const mapDipatchToProps = {
    updateCurrentURL,
    setLayoutScale
}

export default connect(mapStateToProps, mapDipatchToProps)(MainLayout)
