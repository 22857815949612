// React Base Component
import React from 'react'

// Redux connection
import { connect } from 'react-redux'

// React Router components
import {
    Link
} from 'react-router-dom'

// Reactstrap components
import {
    Collapse,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap'

// Custom components
import Logo from './Logo'

// FontAwesome component and icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBlog,
    faBullhorn,
    faUserTie,
    faUserCircle,
    faSignOutAlt,
    faEdit,
    faPhotoVideo,
    faUserCog,
    faSignInAlt,
    faUserPlus,
    faCogs,
    faBars,
    faTimes,
    faCalendarPlus,
    faFile,
    faCalendarAlt,
    faHome
} from '@fortawesome/free-solid-svg-icons'

// CSS Module
import styles from './modules/SidePanel.module.css'

class SideLogo extends React.Component {
    render() {
        return (
            <div className="d-flex justify-content-center align-items-center py-5">
                <Logo width="50%" />
            </div>
        )
    }
}

class SideNavItem extends React.Component {
    render() {
        return (
            <NavItem className={"MouseOverlayTracker " + styles.SideNavItem} style={{ backgroundColor: this.props.active ? "rgba(255, 255, 255, 0.1)" : undefined }}>
                <NavLink className={"text-white"} tag={Link} to={this.props.to}>
                    <div className="d-flex w-75 mx-auto">
                        <div className="flex-shrink-1 flex-grow-0 px-3" style={{ flexBasis: '25%' }}>
                            <FontAwesomeIcon icon={this.props.icon} className={styles.SideNavIcon} />
                        </div>
                        <div className="flex-grow-1 flex-shrink-0">
                            <div className={styles.SideNavText}>{this.props.value}</div>
                        </div>
                    </div>
                </NavLink>
            </NavItem>
        )
    }
}

class SideNav extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Nav {...this.props} vertical className={styles.SideNav}>
                <SideNavItem icon={faHome}     value="Ana Sayfa"     to="/home"           active={this.props.pathname === "/home"}          />
                <SideNavItem icon={faBullhorn} value="Etkinlikler"   to="/announcements"  active={this.props.pathname === "/announcements"} />
                <SideNavItem icon={faBlog}     value="Blog"          to="/blogs"          active={this.props.pathname === "/blogs"}         />
                {this.props.login ? <SideNavItem icon={faFile} value="Yeni Blog" to="/new-blog" active={this.props.pathname === "/new-blog"} /> : null}
                <SideNavItem icon={faUserTie} value="Hakkımızda" to="/about-us" active={this.props.pathname === "/about-us"} />
            </Nav>
        )
    }
}

class LoginNav extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Nav {...this.props} vertical className={styles.SideNav}>
                <SideNavItem icon={faSignInAlt} value="Giriş" to="/login"    active={this.props.pathname === "/profile"} />
                <SideNavItem icon={faUserPlus}  value="Kayıt" to="/register" active={this.props.pathname === "/profile"} />
            </Nav>
        )
    }
}

class UserNav extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Nav {...this.props} vertical className={styles.SideNav}>
                <SideNavItem icon={faUserCircle} value={this.props.name} to="/profile"  active={this.props.pathname === "/profile"}  />
                <SideNavItem icon={faSignOutAlt} value="Çıkış"           to="/logout"   active={this.props.pathname === "/logout"}   />
            </Nav>
        )
    }
}

class EditorNav extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Nav {...this.props} vertical className={styles.SideNav}>
                <SideNavItem icon={faEdit}       value="Blog Yönetimi"  to="/manage/blog"  active={this.props.pathname === "/manage/blog"}  />
                <SideNavItem icon={faPhotoVideo} value="Media Yönetimi" to="/manage/media" active={this.props.pathname === "/manage/media"} />
            </Nav>
        )
    }
}

class AdminNav extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Nav {...this.props} vertical className={styles.SideNav}>
                <SideNavItem icon={faCalendarPlus} value="Yeni Etkinlik"      to="/new-annc"            active={this.props.pathname === "/new-annc"}            />
                <SideNavItem icon={faCalendarAlt}  value="Etkinlik Yönetimi"  to="/manage/announcement" active={this.props.pathname === "/manage/announcement"} />
                <SideNavItem icon={faUserCog}      value="Kullanıcı Yönetimi" to="/manage/user"         active={this.props.pathname === "/manage/user"}         />
                <SideNavItem icon={faCogs}         value="Site Ayarları"      to="/settings"            active={this.props.pathname === "/settings"}            />
            </Nav>
        )
    }
}

class SidePanel extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            navIsOpen: true
        }

        this.overlay = null

        this.onMouseMove = (event) => {
            const posX = event.currentTarget.getBoundingClientRect().left
            const posY = event.target.getBoundingClientRect().top
            
            const x = event.clientX - posX
            const y = event.clientY - posY

            this.overlay.style.setProperty('--x', x + 'px')
            this.overlay.style.setProperty('--y', y + 'px')
        }

        this.toggle = () => {
            this.setState({ navIsOpen: !this.state.navIsOpen })
        }
    }
    
    componentDidMount() {
        this.overlay = document.querySelector('.MouseOverlayTrackerContainer')
        this.overlay.addEventListener('mousemove', this.onMouseMove)
        this.setState({ navIsOpen: (document.documentElement.clientWidth >= 1200 ? true : false) })
    }

    componentWillUnmount() {
        this.overlay.removeEventListener('mousemove', this.onMouseMove)
    }
    
    render() {
        return (
            <div {...this.props}>
                <SideLogo />

                <div className="text-right text-light p-3 d-xl-none" onClick={this.toggle} style={{ fontSize: "14pt" }}>
                    Menu <FontAwesomeIcon icon={this.state.navIsOpen ? faTimes : faBars} />
                </div>

                <Collapse isOpen={this.state.navIsOpen} className="MouseOverlayTrackerContainer">
                        <SideNav pathname={this.props.pathname} login={this.props.login} />
                        {this.props.role >= 1 ? <EditorNav pathname={this.props.pathname} name={this.props.name} /> : null}
                        {this.props.role >= 2 ? <AdminNav  pathname={this.props.pathname} name={this.props.name} /> : null}
                        {this.props.login     ? <UserNav   pathname={this.props.pathname} name={this.props.name} /> : <LoginNav pathname={this.props.pathname} name={this.props.name} />}
                </Collapse>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login ? 1 : 0,
        pathname: state.pathname,
        name: state.fullname,
        role: state.role
    }
}

const mapDipatchToProps = {
    // empty
}

export default connect(mapStateToProps, mapDipatchToProps)(SidePanel)
