// React Base Component
import React from 'react'

// Reactstrap Components
import {
    Button,
    Col,
    CustomInput,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    PopoverBody,
    PopoverHeader,
    Row,
    UncontrolledPopover
} from 'reactstrap'

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCaretDown,
    faSearch
} from '@fortawesome/free-solid-svg-icons';

// Custom Components
import TableList from './TableList'
import Loader from '../global/Loader'
import Announcementt from '../announcement/Announcement'
import NewAnnouncement from '../textEditor/NewAnncouncement'
import recaptcha from '../helper/ReCaptcha'

class Filter extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            filter: {
                search: "",
                active: true,
                passive: true
            },
            isOpen: false,
            change: false
        }

        this.handleChange = (event) => {
            const update = { ...this.state }
            update.filter[event.target.name] = event.target.name === "search" ? event.target.value : event.target.checked
            update.change = true
            this.setState(update)
        }
    }

    componentDidUpdate() {
        if (this.state.change) {
            this.setState({ change: false }, () => {
                this.props.update(this.state.filter)
            })
        }
    }

    render() {
        return (
            <Form className="py-1">
                <Row noGutters>
                    <Col className="flex-grow-1 flex-shrink-0">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Button color="dark" id="filters" type="button" block>
                                    Filtreler <FontAwesomeIcon icon={faCaretDown} />
                                </Button>
                                <UncontrolledPopover trigger="legacy" placement="bottom" isOpen={this.state.isOpen} target="filters" toggle={() => this.setState({ isOpen: !this.state.isOpen })}>
                                    <PopoverHeader>Filtreler</PopoverHeader>
                                    <PopoverBody>
                                        <FormGroup>
                                            <Label>Durum</Label>
                                            <div>
                                                <CustomInput type="checkbox" name="active" id="active" label={"Aktif"} checked={this.state.filter.active} onChange={this.handleChange} />
                                                <CustomInput type="checkbox" name="passive" id="passive" label={"Pasif"} checked={this.state.filter.passive} onChange={this.handleChange} />
                                            </div>
                                        </FormGroup>
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </InputGroupAddon>
                            <Input type="text" name="search" placeholder="Hızlı Arama" value={this.state.filter.search} onChange={this.handleChange} />
                            <InputGroupAddon addonType="append">
                                <Button color="dark">
                                    <FontAwesomeIcon icon={faSearch} />
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>
            </Form>
        )
    }
}

class ViewAnnouncement extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            editor: false,
            data: {}
        }

        this.setAnncData = (data) => {
            this.setState({ data: data })
        }

        this.setEditorState = () => {
            this.setState({ editor: false })
        }

    }

    componentDidUpdate() {
        if (!this.props.editorIsOpen && this.state.editor) {
            this.setState({ editor: false })
        }
    }
    render() {

        return (
            <Modal {...this.props} className="modal-xl" scrollable>
                <ModalHeader toggle={this.props.toggle}>Etkinlik içeriği</ModalHeader>
                <ModalBody>
                    {
                        this.state.editor ? (
                            <NewAnnouncement manage hideUserList data={this.state.data} url={this.props.url} setEditorState={this.setEditorState} />
                        ) : (
                            <Announcementt manage data={this.props.data} url={this.props.url} setAnncData={this.setAnncData} />
                        )
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="dark" outline onClick={() => this.setState({ editor: !this.state.editor })}>{this.state.editor ? "Geri Dön" : "İçeriği Düzenle"}</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

class Editor extends Modal {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            userList: []
        }

        this.handleChange = (event) => {
            const update = { ...this.state }
            update.data[event.target.name] = event.target.name === 'status' ? event.target.checked : event.target.value
            this.setState(update)
        }

        this.handleChangeURL = (event) => {
            const update = { ...this.state }
            update.data.url = event.target.value.toLowerCase()

            // Turkish char filter
            update.data.url = update.data.url.replace(/ç/g, "c")
            update.data.url = update.data.url.replace(/ğ/g, "g")
            update.data.url = update.data.url.replace(/ı/g, "i")
            update.data.url = update.data.url.replace(/ö/g, "o")
            update.data.url = update.data.url.replace(/ş/g, "s")
            update.data.url = update.data.url.replace(/ü/g, "u")

            // Space to dash
            update.data.url = update.data.url.replace(/\s+/g, "-")
            update.data.url = update.data.url.replace(/\-+/g, "-")

            // Remove non-alphanumerics
            update.data.url = (update.data.url.match(/[A-Za-z0-9\-]/g) || []).join("")

            this.setState(update)
        }

        this.onBlurURL = (event) => {
            const update = { ...this.state }
            update.data.url = update.data.url.replace(/^\-|\-$/g, "")
            this.setState({ invalid: (event.target.value.length < 4) })
        }

        this.handleSubmit = () => {
            if (!this.state.invalid) {
                recaptcha.getReCaptchaToken(token => {
                    fetch('/api/manage/announcement', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ...this.state.data,
                            date: undefined,
                            uname: undefined,
                            url: '/' + this.state.data.url,
                            gToken: token
                        })
                    }).then(res => {
                        if (res.ok) {
                            this.props.save()
                        } else {
                            throw new Error('Failed to save changes.')
                        }
                    }).catch(err => {
                        console.error(err.message)
                    })
                })
            }
        }

        this.updateFormFields = () => {
            this.setState({ data: { ...this.props.data, url: this.props.data.url.substr(1) } }, () => {
                fetch('/api/manage/announcement/user-list').then(res => {
                    if (res.ok) return res.json()
                    throw new Error('Failed to GET available users.')
                }).then(data => {
                    this.setState({ userList: data })
                }).catch(err => {
                    console.error(err.message)
                })
            })
        }
    }

    componentDidMount() {
        if (this.props.data.url) {
            this.updateFormFields()
        }
    }

    componentDidUpdate(oldProps) {
        if (this.props.data.url && ((oldProps.data.url !== this.props.data.url) || (oldProps.isOpen === false && this.props.isOpen === true))) {
            this.updateFormFields()
        }
    }

    render() {
        return (
            <Modal {...this.props} scrollable>
                <ModalHeader>Etkinlik Ayarları</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label>Durum</Label>
                            <CustomInput
                                type="switch"
                                name="status"
                                id="status-switch"
                                label={this.state.data.status ? "Aktif" : "Pasif"}
                                checked={this.state.data.status}
                                onChange={this.handleChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>URL</Label>
                            <Input invalid={this.state.invalid} type="text" name="url" value={this.state.data.url} onChange={this.handleChangeURL} onBlur={this.onBlurURL} />
                            <FormFeedback>URL en az 4 geçerli karakterden oluşmalı</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Yazar</Label>
                            <Input type="select" name="author" value={this.state.data.author} onChange={this.handleChange}>
                                {this.state.userList.map((element, index) => (
                                    <option key={index} value={element.uname}>{element.name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="dark" outline onClick={this.props.toggle}>İptal</Button>
                    <Button color="dark" onClick={this.handleSubmit}>Kaydet</Button>
                    <Button color="dark" onClick={() => (this.props.viewAnnc())}>Etkinliği gör</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

class Announcement extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            raw: [],
            data: [],
            loading: true,
            failed: false,
            failMessage: "",
            editorIsOpen: false,
            blogIsOpen: false,
            blogData: {},
            editTarget: "",
            sort: {
                index: -1,
                order: false
            }
        }

        this.sort = async (params) => {
            const update = { ...this.state }
            update.sort = { ...params }
            update.data = this.toSortable(JSON.parse(JSON.stringify(update.raw)))

            if (params.index !== -1) {
                update.data.sort((e1, e2) => {
                    if (typeof e1[params.index] === 'string') {
                        return e1[params.index].localeCompare(e2[params.index], "tr") * (params.order ? 1 : -1)
                    } else {
                        return ((e1[params.index] < e2[params.index]) ? -1 : ((e1[params.index] > e2[params.index]) ? 1 : 0)) * (params.order ? 1 : -1)
                    }
                })
            }

            update.data.forEach(element => {
                element[2] = element[2] ? element[2].toLocaleDateString("tr") : null
            })

            this.setState(update)
        }

        this.filter = async (filters = {}) => {
            if (Object.keys(filters).length == 0) {
                this.setState({ data: Array.from(this.state.raw) })
            } else {
                const update = { ...this.state }

                update.data = this.state.raw.filter(element => {
                    const regexp = new RegExp(filters.search.replace(/(^\s+)|(\s+$)/g, "").replace(/\s+/g, " "), "ig")
                    const search = !filters.search || [element.title, element.author, element.url].join('|').match(regexp) ? true : false

                    const active = filters.active ? element.status === 1 : false
                    const passive = filters.passive ? element.status === 0 : false

                    return search && (active || passive)
                })

                update.data = this.toReadable(update.data)

                this.setState(update)
            }
        }

        this.edit = async (index) => {
            const target = { ...this.state.raw.find((element) => element.url == this.state.data[index][4]) }
            target.author = target.uname
            target.oldUrl = target.url
            this.setState({ editorIsOpen: true, editTarget: target })
        }

        this.save = () => {
            this.setState({ editorIsOpen: false, loading: true }, () => {
                this.fetchUserTable()
            })
        }

        this.toSortable = (array) => {
            const newArray = []

            array.forEach(element => {
                newArray.push([
                    element.title,                                // title
                    element.author,                               // autoher
                    element.date ? new Date(element.date) : null, // date
                    element.status ? "Aktif" : "Pasif",           // status
                    element.url                                   // url
                ])
            })

            return newArray
        }

        this.toReadable = (array) => {
            const newArray = []

            array.forEach(element => {
                newArray.push([
                    element.title,                                     // title
                    element.author,                                    // autoher
                    (new Date(element.date)).toLocaleDateString("tr"), // date
                    element.status ? "Aktif" : "Pasif",                // status    
                    element.url                                        // url
                ])
            })

            return newArray
        }

        this.fetchUserTable = () => {
            fetch('/api/manage/announcement').then(res => {
                if (res.ok) return res.json()
                throw new Error('Cannot GET /api/manage/announcement')
            }).then(data => {
                this.setState({ raw: data, data: this.toReadable(data), loading: false })
            }).catch(err => {
                this.setState({ loading: false, failed: true, failMessage: err.message })
                console.error(err.message)
            })
        }

        this.viewAnnc = () => {
            this.setState({ blogIsOpen: true })
        }
    }

    componentDidMount() {
        this.fetchUserTable()
    }

    render() {
        return (
            <div>
                <Editor data={this.state.editTarget} save={this.save} isOpen={this.state.editorIsOpen} toggle={() => this.setState({ editorIsOpen: !this.state.editorIsOpen })} viewAnnc={this.viewAnnc} />
                <ViewAnnouncement data={this.state.blogData} isOpen={this.state.blogIsOpen} editorIsOpen={this.state.editorIsOpen} toggle={() => this.setState({ blogIsOpen: !this.state.blogIsOpen })} url={this.state.editTarget.url} />
                <Row>
                    <Col>
                        <Filter update={this.filter} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.state.loading ? <Loader /> : (
                            <TableList
                                sort={this.sort}
                                sortParams={this.state.sort}
                                edit={this.edit}
                                headers={["Başlık", 'Yazar', 'Tarih', 'Durum', "Url"]}
                                data={this.state.data}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Announcement
