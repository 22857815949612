// React Base Component
import React from 'react'

// React Base Component
import {
    Link
} from 'react-router-dom'

// Reactstrap Components
import {
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    Button
} from 'reactstrap'

// Custom Components
import Logo from '../global/Logo'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'

class VerifyAccount extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            success: true,
        }
    }

    render() {
        return (
            <Row className="h-100" noGutters>
                <Col xs="12" xl="6" style={{ background: '#231f20', fontFamily: 'Roboto' }} className="d-flex flex-column justify-content-center">
                    <Row className="flex-grow-1" noGutters>{/* space-top */}</Row>
                    <Row noGutters>
                        <Col className="d-flex justify-content-center align-items-center">
                            <Logo width="50%" className="px-6" />
                        </Col>
                    </Row>
                    <Row className="flex-grow-1" noGutters>{/* space-bottom */}</Row>
                    <Row className="align-self-end" noGutters>
                        <Col>
                            <Nav>
                                <NavItem>
                                    <NavLink className="text-light" tag={Link} to="/announcements">Etkinlikler</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="text-light" tag={Link} to="/blogs">Blog</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className="text-light" tag={Link} to="/about-us">Hakkımızda</NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                </Col>
                <Col xs="12" xl="6" className="d-flex align-items-center">
                    <Row className="w-100 justify-content-center" noGutters>
                        <Col xs="12" md="6" className="px-3">
                            <div>
                                <h3 className="d-flex justify-content-center text-center text-success" >Hesabın aktive edildi giriş yapabilirsin</h3>
                                <h1 className="d-flex justify-content-center mb-0" style={{ color: "green", height: "250px" }}><FontAwesomeIcon icon={faCheckCircle} /> </h1>
                                <Col xs="12">
                                    <Button color="dark" outline size="block" tag={Link} to="/login">Giriş Yap</Button>
                                </Col>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default VerifyAccount
