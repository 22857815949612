// React Base Component
import React from 'react'

// Reactstrap Components
import {
    Button,
    Col,
    CustomInput,
    Form,
    Input,
    Row
} from 'reactstrap'

// Custom Components
import Loader from '../global/Loader'
import recaptcha from '../helper/ReCaptcha'

class Contest extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            img: undefined,
            alt: "",
            loading: false
        }

        this.handleFileChange = (event) => {
            this.setState({ img: event.target.files[0] })
        }
        
        this.handleAltChange = (event) => {
            this.setState({ alt: event.target.value })
        }

        this.handleSubmit = (event) => {
            event.preventDefault()

            if (this.state.img && this.state.alt) {
                this.setState({ loading: this.props.profile ? null : true }, () => {
                    const formData = new FormData()
                    formData.append('img', this.state.img)
                    formData.append('alt', this.state.alt)
                    
                    this.props.profile ? (
                        this.fetchAvatar(formData)
                    ):(
                        this.fetchImg(formData)
                    )
                })
            } else {
                alert("Lütfen açıklama girin.")
            }
        }

        this.fetchImg = (formData) => {
            recaptcha.getReCaptchaToken(token => {
                formData.append("gToken", token)

                fetch('/api/media/upload/image',{
                    method: 'POST',
                    body: formData
                }).then(res => {
                    if (res.ok) return res.json()
                    throw new Error('error işte')
                }).then(data => {
                    return this.props.modal ? (
                        this.props.setMediaItem(data.src)
                    ) : null
                }).catch(err => {
                    console.error(err.message)
                }).finally(() => {
                    this.setState({ loading: false })
                })
            })
        }

        this.fetchAvatar = (formData) => {
            recaptcha.getReCaptchaToken(token => {
                formData.append("gToken", token)

                fetch('/api/media/upload/avatar',{
                    method: 'POST',
                    body: formData
                }).then(res => {
                    if (res.ok) return res.json()
                    throw new Error('error işte')
                }).then(data => {
                    return this.props.modal ? (
                        this.props.setMediaItem(data.src)
                    ) : null
                }).catch(err => {
                    console.error(err.message)
                }).finally(() => {
                    this.setState({ loading: false })
                })
            })
        }
    }

    render() {
        return (
            <Form className="p-3" onSubmit={this.handleSubmit}>
                {this.props.modal ? (
                    <div>
                        <Row>
                            <Col >
                                <CustomInput type="file" accept="image/*" name="file" id="file" label="Dosya Seç" onChange={this.handleFileChange} />
                            </Col>
                            <Col xs="4">
                                <Input type="text" name="alt" value={this.state.alt} placeholder="Açıklama" onChange={this.handleAltChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="3" className="mt-3 mb-0 ml-auto">
                                <Button type="submit">Gönder</Button>
                            </Col>
                        </Row>
                    </div>
                ):(
                    <Row>
                        <Col className="flex-shrink-0 flex-grow-1">
                            <CustomInput type="file" accept="image/*" name="file" id="file" label="Dosya Seç" onChange={this.handleFileChange} />
                        </Col>
                        <Col xs="3">
                            <Input type="text" name="alt" value={this.state.alt} placeholder="Açıklama" onChange={this.handleAltChange} />
                        </Col>
                        <Col className="flex-shrink-1 flex-grow-0">
                            <Button color="dark" type="submit">Gönder</Button>
                        </Col>
                    </Row>
                )}
                {this.state.loading ? (
                    <Row>
                        <Col xs="12">
                            <Loader />
                        </Col>
                        <Col xs="12">
                            <em>Yükleniyor...</em>
                        </Col>
                    </Row>
                ) : null}
            </Form>
        )
    }
}

export default Contest
