// React Base Component
import React from 'react'

// Reactstrap Components
import {
    Col,
    Row
} from 'reactstrap'

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons'
import {
    faFacebookF,
    faGithubAlt,
    faInstagram,
    faLinkedinIn,
    faTwitter,
    faYoutube
} from '@fortawesome/free-brands-svg-icons'

// CSS Module
import styles from './modules/Social.module.css'

class SocialIcon extends React.Component {
    render() {
        return (
            this.props.active ? (
                <Col xs="12" md="4" className="py-1">
                    <a className={[styles.Container, "text-decoration-none", "text-dark", "rounded-pill", "py-2", "px-2"].join(" ")} href={this.props.link}>
                        <div className={[styles.Icon, this.props.className].join(' ')}>
                            <FontAwesomeIcon icon={this.props.icon} />
                        </div>
                        <div className={styles.Path}>
                            {this.props.name}
                        </div>
                    </a>
                </Col>
            ):(null)
        )
    }
}

class Social extends React.Component {
    render() {
        return (
            <Row className="w-100 pb-2" noGutters>
                <SocialIcon own={this.props.own} className={styles.Instagram} icon={faInstagram}     name={"Instagram"} active={this.props.content.instagram} link={"https://instagram.com/" + this.props.content.instagram} />
                <SocialIcon own={this.props.own} className={styles.Facebook}  icon={faFacebookF}     name={"Facebook"}  active={this.props.content.facebook}  link={"https://facebook.com/"  + this.props.content.facebook}  />
                <SocialIcon own={this.props.own} className={styles.YouTube}   icon={faYoutube}       name={"YouTube"}   active={this.props.content.youtube}   link={"https://youtube.com/"   + this.props.content.youtube}   />
                <SocialIcon own={this.props.own} className={styles.Twitter}   icon={faTwitter}       name={"Twitter"}   active={this.props.content.twitter}   link={"https://twitter.com/"   + this.props.content.twitter}   />
                <SocialIcon own={this.props.own} className={styles.Linkedin}  icon={faLinkedinIn}    name={"Linkedin"}  active={this.props.content.linkedin}  link={"https://linkedin.com/"  + this.props.content.linkedin}  />
                <SocialIcon own={this.props.own} className={styles.Github}    icon={faGithubAlt}     name={"GitHub"}    active={this.props.content.github}    link={"https://github.com/"    + this.props.content.github}    />
                <SocialIcon own={this.props.own} className={styles.Website}   icon={faGlobeAmericas} name={"Website"}   active={this.props.content.website}   link={"http://"                + this.props.content.website}   />
            </Row>
        )
    }
}

export default Social
