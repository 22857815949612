// React Base Component
import React from 'react'

// React Router Components
import { Redirect } from 'react-router-dom'

// Redux store connection
import { connect } from 'react-redux'
import { loginHandle } from '../helper/ReduxActions'

class Logout extends React.Component {
    componentDidMount() {
        fetch('/api/logout').then((res) => {
            // Doesn't matter success or not
            this.props.loginHandle({ name: undefined, role: 0, login: false })
        })
    }

    render() {
        return <Redirect to="/" />
    }
}

export default connect(null, { loginHandle })(Logout)
