// React Base Component
import React from 'react'

// React Redux Connection
import { connect } from 'react-redux'

// Reactstrap Components
import {
    Col,
    Row,
    Button,
    Input
} from 'reactstrap'

//Fontawsome Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

//Custom Components
import recaptcha from '../../helper/ReCaptcha'

//CSS Module
import styles from './modules/Comment.module.css'

import Loader from '../../global/Loader'
class CommentPost extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            blogID: this.props.blogId,
            replyTo: null,
            content: "",
            status: 1,
            avatar: undefined,
            loading: true
        }

        this.postCommentMeta = () => {
            recaptcha.getReCaptchaToken(token => {
                const data = {...this.state}
                data.gToken = token

                delete data.loading
                delete data.avatar

                fetch('/api/comment/new-comment', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => {
    
                    if (res.ok) {
                        this.props.fetchCommentData()
                        return res.json()
                    } else {
                        throw new Error('Can not post the comment!')
                    }
                }).then(data => {
                    this.setState({ content: "" })
                }).catch(err => {
                    console.error(err.message)
                })

            })
        }
    }

    componentDidMount() {
        fetch('/api/comment/avatar').then(res => {
            if (res.ok) return res.json()
            throw new Error('Can not get profile picture!')
        }).then(data => {
            this.setState({ avatar: data.avatar, loading: false })
        }).catch(err => {
            console.error(err.message)
        })
    }

    render() {
        return (
            <div>
                <hr style={{ borderTop: "2px solid lightgray" }} />
                <Row>
                    <Col className="pb-3">
                        <p style={{ color: "#686868", fontWeight: "bold", fontSize: "25px" }}>Bir cevap yazın;</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs="auto" className="pr-1">
                        {
                            this.state.loading ? (
                                <Loader />
                            ) : (
                                <div className={styles.Avatar} style={{ minWidth: "66px", backgroundImage: `url('${this.state.avatar}')` }} />
                            )
                        }
                    </Col>
                    <Col className="pl-0">
                        <p style={{ display: "inline", color: "#686868", fontWeight: "bold", fontSize: "20px" }}>{this.props.name} olarak yorum yap</p>
                        <Row>
                            <Col className="pt-2">
                                <Input rows={5} required type="textarea" name="content" value={this.state.content} onChange={(event) => this.setState({ content: event.target.value })} />
                                <Row>
                                    <Col className="d-flex justify-content-end pt-3 pb-3">
                                        <Button color="dark" onClick={this.postCommentMeta}>Yorum gönder &nbsp; <FontAwesomeIcon icon={faPaperPlane} /> </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        name: state.fullname
    }
}

const mapDipatchToProps = {
    // empty
}

export default connect(mapStateToProps, mapDipatchToProps)(CommentPost)