// React Base Component
import React from 'react'

// React Redux Components
import { Provider } from "react-redux"
import { createStore } from "redux"

// Custom Components
import Router from "./Router"

// Reducer
import rootReducer from './helper/ReduxReducer'

// Redux Provider wrapper
class Redux extends React.Component {
    render() {
        return (
            <Provider store={createStore(rootReducer)}>
                <Router {...this.props} />
            </Provider>
        )
    }
}

export default Redux
