// React Base Component
import React from 'react'

// Custom Components
import SplitLayout from './SplitLayout'
import LoginForm from './LoginForm'

class Login extends React.Component {
    render() {
        return (
            <SplitLayout page={<LoginForm className="w-100" />} />
        )
    }
}

export default Login
