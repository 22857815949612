// React Base Component
import React from 'react'

// React Redux Connection
import { connect } from 'react-redux'

// Reactstrap Components
import {
    Col,
    Row
} from 'reactstrap'

import { Link } from 'react-router-dom'

//Custom Components
import Reactions from './Reactions'
import ManageComment from './ManageComment'
//CSS Module
import styles from './modules/Comment.module.css'

class Reply extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            reply: this.props.reply,
            status: this.props.reply.status
        }

        /**
         * Format datestring to TR locale
         * @param {string} date The ISO-Date string 
         */
        this.formatDate = (date = "") => {
            return (new Date(date)).toLocaleDateString('tr-TR')
        }

        /**
         * Format datestring to ss:mm:hh
         * @param {string} date The ISO-Date string 
         */
        this.formatHour = (date = "") => {
            return (new Date(date)).toTimeString().split(' ')[0].slice(0, 5)
        }

        this.generateReactionObj = (includesMyReaction, reaction1, reaction2, reaction3, reaction4) => {
            const reactionObj = {
                includesMyReaction,
                reaction1,
                reaction2,
                reaction3,
                reaction4
            }
            return reactionObj
        }

        this.setStatus = () => {
            this.setState({ status: !this.state.status })
        }
    }

    render() {
        return (
            <div className="mt-1 mb-1">
                <Row>
                    <Col>
                        <Row>
                            {this.props.role >= 1 ? (
                                <Col xs="auto" className="ml-auto">
                                    <ManageComment id={this.state.reply.id} name={this.state.reply.name} content={this.state.reply.content}
                                        status={this.state.reply.status} setStatus={this.setStatus} fetchCommentData={this.props.fetchCommentData} />
                                </Col>
                            ) : undefined}
                            <Col xs="auto" className="ml-0 pb-0 pr-1">
                                <Link className="text-decoration-none" to={"/profile/" + this.state.reply.uname}>
                                    <p className={styles.replyText}>{this.state.reply.name}</p>
                                </Link>
                                <p className={styles.inline + " pl-1 font-italic"}>buna yanıt olarak;</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="auto" className="ml-auto pt-0 pr-1">
                                <p className={styles.datesOfComments}>{this.formatDate(this.state.reply.date) + " "}</p>
                                <p className={styles.datesOfComments}>{this.formatHour(this.state.reply.date)}</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="auto" className="pl-0 pb-2 px-0">
                        <Link to={"/profile/" + this.state.reply.uname}>
                            <div className={styles.Avatar} style={{ minWidth: '52px', backgroundImage: this.state.reply.avatar ? `url('${this.state.reply.avatar}')` : `url('logo512.png')` }} />
                        </Link>
                    </Col>
                </Row>
                {this.state.status ? (
                    <div>
                        <Row>
                            <Col xs="auto" className="ml-auto">
                                <p>{this.state.reply.content}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="auto" className="ml-auto">
                                <Reactions key={this.state.reply.index} reply commentID={this.state.reply.id} reactionObj={this.generateReactionObj(
                                    this.state.reply.includesMyReaction,
                                    this.state.reply.reaction1num,
                                    this.state.reply.reaction2num,
                                    this.state.reply.reaction3num,
                                    this.state.reply.reaction4num,
                                )} />
                                <hr />
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <Row>
                        <Col xs="auto" className="ml-auto">
                            <p className={styles.noComment} style={{ fontSize: "17px" }}>Bu yorum kısıtlanmış.</p>
                        </Col>
                    </Row>
                )}
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        name: state.name,
        role: state.role
    }
}

const mapDipatchToProps = {
    // empty
}

export default connect(mapStateToProps, mapDipatchToProps)(Reply)