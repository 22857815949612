// React Base Component
import React from 'react'

// React Redux Connection
import { connect } from 'react-redux'

// React Router Components
import {
    Link, Redirect
} from 'react-router-dom'

// Reactstrap Components
import {
    Col,
    Row,
    Card,
    CardBody,
    Container
} from 'reactstrap'

// Custom Components
import Header from '../global/Header'
import Loader from '../global/Loader'

// CSS Module
import styles from './modules/Announcement.module.css'

class AnnouncementList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            list: [],
            loading: true,
            failed: false
        }

        /**
         * Format datestring to TR locale
         * @param {string} date The ISO-Date string 
         */
        this.formatDate = (date = "") => {
            return (new Date(date)).toLocaleDateString('tr-TR')
        }
        
        /**
         * Format datestring to ss:mm:hh
         * @param {string} date The ISO-Date string 
         */
        this.formatHour = (date = "") => {
            return (new Date(date)).toTimeString().split(' ')[0]
        }
    }

    componentDidMount() {
        fetch('/api/announcement').then(res => {
            if (res.ok) return res.json()
            throw new Error('Cannot fetch announcements!')
        }).then(data => {

            this.setState({ list: data, loading: false })
        }).catch(err => {
            this.setState({ loading: false, failed: true })
            console.error(err.message)
        })
    }
    
    render() {
        return (
            this.state.loading ? (
                <Loader />
            ):(
                this.state.failed ? (
                    <Redirect to="/not-found" />
                ):(
                    <Container fluid className="h-100">
                        <Row>
                            <Col>
                                <Header value="Etkinlikler" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {!this.props.login ? <p><b>Not: </b>Dersler ile ilgili duyuru ve blog görmek için giriş yapmanız gerekmektedir</p> : null}
                            </Col>
                        </Row>
                        <Row className="row-cols-3 py-3">{
                            this.state.list.map(element => (
                                <Col key={element.url} xs="12" md="6" xl="4">
                                    <Card className="border-0" style={{ backgroundColor: "transparent" }}>
                                        <Link to={"/announcements" + element.url}>
                                            <div style={{backgroundImage:  `url(${element.media})` || "/logo512.png"}} alt={element.alt} className={[styles.Aspect16x9, styles.CardImg].join(' ')} />
                                        </Link>
                                        <CardBody>
                                            <span className="d-flex justify-content-between">
                                                <p className="text-muted">{this.formatDate(element.date)}</p>
                                                <p className="text-muted">{this.formatHour(element.date)}</p>
                                            </span>
                                            <h5 className="text-center">{element.title}</h5>
                                            <p className="text-center">{element.summary}</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))
                        }</Row>
                    </Container>
                )
            )
        )
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login
    }
}

const mapDipatchToProps = {
    // empty
}

export default connect(mapStateToProps, mapDipatchToProps)(AnnouncementList)
