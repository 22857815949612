//React Component
import React from 'react'

//React Router Dom Component
import { Link } from 'react-router-dom'

//FontAwsome Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'

class Error extends React.Component {
    render() {
        return (
            <div className="d-flex justify-content-center align-items-center mb-0" style={{ height: "500px" }}>
                {
                    this.props.blog ? (
                        <div>
                            <h3 className="d-flex justify-content-center text-center text-danger">Blog gönderilirken bir hata meydana geldi!</h3>
                            <h6 className="d-flex justify-content-center text-center text-danger" >Lütfen bunu bize bildir - computerclubatilim@gmail.com</h6>
                            <p className="text-center mt-5" style={{ fontSize: "25px" }}>Yeniden <Link onClick={() => this.props.setErrState()}>oluştur</Link> </p>

                        </div>
                    ) : (
                        <div>
                            <h3 className="d-flex justify-content-center text-center text-danger">Etkinlik gönderilerken bir hata meydana geldi!</h3>
                            <h6 className="d-flex justify-content-center text-center text-danger" >Lütfen bunu bize bildir - computerclubatilim@gmail.com</h6>
                            <p className="text-center mt-5" style={{ fontSize: "25px" }}>Yeniden <Link onClick={() => this.props.setErrState()}>oluştur</Link> </p>
                        </div>
                    )
                }
            </div>

        )
    }
}

export default Error