// React Base Components
import React from 'react'
import ReactDOM from 'react-dom'

// Custom Components
import Redux from './component/Redux'

// Bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css'

ReactDOM.render(
    <React.StrictMode>
        <Redux />
    </React.StrictMode>,
    document.getElementById('root')
)
