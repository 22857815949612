// React Base Component
import React from 'react'

// Redux connection
import { connect } from 'react-redux';

// React Router Components
import {
    Link
} from 'react-router-dom'

// Reactstrap Components
import {
    Col,
    Row,
    Container
} from 'reactstrap'

// Custom Components
import Header from '../global/Header'
import Loader from '../global/Loader'

// CSS Module
import styles from './modules/Blog.module.css'

class BlogList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            list: [],
            loading: true,
            category: this.props.category ? (this.props.category.substring('1').charAt(0).toUpperCase() + this.props.category.slice(2)) : null
        }

        /**
         * Format datestring to TR locale
         * @param {string} date The ISO-Date string 
         */
        this.formatDate = (date = "") => {
            return (new Date(date)).toLocaleDateString('tr-TR')
        }

        /**
         * Format datestring to ss:mm:hh
         * @param {string} date The ISO-Date string 
         */
        this.formatHour = (date = "") => {
            return (new Date(date)).toTimeString().split(' ')[0]
        }

        this.fetchBlogData = () => {
            this.setState({loading: true}, () => {
                this.props.category ? (
                    fetch('/api/blog/category/' + this.props.category.substring('1')).then(res => {
                        if (res.ok) {
                            this.setState({loading: false})
                            return res.json()
                        }
                        throw new Error('Cannot fetch blogs!')
                    }).then(data => {
                        this.setState({ list: data})
                    }).catch(err => {
                        console.error(err.message)
                    })
                ) : (
                    fetch('/api/blog').then(res => {
                        if (res.ok) {
                            this.setState({loading: false})
                            return res.json()
                        } else {
                            throw new Error('Cannot fetch blogs!')
                        }
                    }).then(data => {
                        this.setState({ list: data })
                    }).catch(err => {
                        console.error(err.message)
                    })
                )
            })
        }
    }


    componentDidMount() {
        this.fetchBlogData()
    }

    componentDidUpdate(oldProps) {
        if (oldProps.pathname !== this.props.pathname) {
            this.fetchBlogData()
        }
    }

    render() {
        return (
            this.state.loading ? (
                <Loader />
            ) : (
                <Container fluid="lg" className="align-items-center">
                    <Row>
                        <Col>
                            <Header value={this.props.category ? this.state.category + " içeren bloglar" : "Bloglar"} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {!this.props.login ? <p><b>Not: </b>Dersler ile ilgili duyuru ve blog görmek için giriş yapmanız gerekmektedir</p> : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            {this.state.list.map((element,index) => (
                                <Row key={index} className="mb-5 d-flex justify-content-center">
                                    <Col xs="12" xl="6" >
                                        <Link to={"/blogs" + element.url} >
                                            <div style={{backgroundImage: `url(${element.media})`}} alt={element.alt} className={[styles.Aspect16x9, styles.CardImg].join(' ')} />
                                        </Link>
                                    </Col>
                                    <Col xs="11"  xl="6" className="pl-xl-2 px-0">
                                        <Link to={"/blogs" + element.url} className={"text-decoration-none text-dark"}>
                                            <p className="text-center text-sm-left my-0" style={{fontSize: "25px"}}>{element.title}</p>
                                        </Link>
                                        <p className="text-muted font-italic my-0">{this.formatDate(element.date)}</p>
                                        <span className="d-flex justify-content-start my-0">
                                            <p className="pr-2 text-muted font-italic my-0">Yazar:</p>
                                            <p className="my-0">{element.name}</p>
                                        </span>
                                        {
                                            element.summary ? (
                                                <p className="font-italic" style={{fontSize: "20px"}}>{element.summary}</p>
                                            ) : (
                                                <p  className="text-muted font-italic">Özet bilgi paylaşılmamış.</p>
                                            )
                                        }
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                    </Row>
                </Container>
            )
        )
    }
}

const mapStateToProps = (state) => {
    return {
        pathname: state.pathname,
        login: state.login
    }
}

const mapDipatchToProps = {
    // empty
}

export default connect(mapStateToProps, mapDipatchToProps)(BlogList)
