// React Base Component
import React from 'react'

// Reactstrap Components
import {
    Col,
    Row,
    Button,
    Input
} from 'reactstrap'

//Custom Components
import Reactions from './Reactions'
import recaptcha from '../../helper/ReCaptcha'

class CommentReply extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            commentObj: {
                blogID: this.props.blogID,
                replyTo: this.props.id,
                content: "",
                status: 1,
            },
            show: false
        }

        this.postCommentMeta = () => {
            recaptcha.getReCaptchaToken(token => {
                fetch('/api/comment/new-comment', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ...this.state.commentObj,
                        gToken: token
                    })
                }).then(res => {
    
                    if (res.ok) {
                        this.props.fetchCommentData()
                        return res.json()
                    } else {
                        throw new Error('Can not post the comment!')
                    }
                }).then(data => {
                    this.setState({ content: "" })
                }).catch(err => {
                    console.error(err.message)
                })
            })
        }
    }

    render() {
        return (
            <div>
                <Reactions setShowState={() => this.setState({ show: !this.state.show })} commentID={this.props.id} reactionObj={this.props.reactionObj} />
                {
                    this.state.show ? (
                        <div>
                            <Row className="mt-3 mb-3">
                                <Col>
                                    <Input rows={5} required type="textarea" name="content" value={this.state.commentObj.content} onChange={(event) => this.setState({ commentObj: { ...this.state.commentObj, content: event.target.value } })} />
                                </Col>
                            </Row>
                            <Row className="justify-content-end">
                                <Col xs="auto">
                                    <Button color="dark" outline onClick={() => this.setState({ show: !this.state.show, content: "" })}>İptal</Button>
                                </Col>
                                <Col xs="auto">
                                    <Button color="dark" onClick={this.postCommentMeta}>Yorumu Gönder</Button>
                                </Col>
                            </Row>
                        </div>
                    ) : (undefined)
                }
            </div>

        )
    }
}

export default CommentReply