// React Base Component
import React from 'react'

import {
    Col,
    Row
} from 'reactstrap'

import styles from './modules/Banner.module.css'
import TypeWritter from '../global/TypeWritter'

class Banner extends React.Component {
    render() {
        return (
            <Row className={styles.Banner + " text-light justify-content-center py-3"} noGutters>
                <Col className={["text-center", styles.Content].join(" ")} xs="auto">
                    <img className="d-block mx-auto pt-4 pb-1 px-3" src="/atilim.png" width="100%" style={{ maxWidth: "512px" }} />
                    <TypeWritter value="BİLGİSAYAR TOPLULUĞU" />
                    <h2 className="h5 mt-2 font-weight-normal" style={{ fontSize: "24px" }}>Topluluk Web Sayfası</h2>
                    <p className="py-3 font-italic">Klüp etkinliklerimiz, öğrencilerimizin paylaştıkları bloglar ve çok dahası fazlası...</p>
                </Col>
                <div className={styles.Background} style={{ backgroundImage: 'url("/home.png")' }} />
            </Row>
        )
    }
}

export default Banner