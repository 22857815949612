// Default theme
const theme = {
    primary: "#231f20",
    secondary: "#231f20",
    light: "#f7f7f7",
    dark: "#231f20",
}

const defaultStore = {
    login: false,
    layoutScale: {
        width: 0,
        height: 0
    }
}

// Read initial state from local storage
//const initialState = JSON.parse(localStorage.getItem('store')) || {}

// DELETE THIS!!!
const initialState = { ...JSON.parse(localStorage.getItem('store')), theme, ...defaultStore }

// The reducer
const reducer = (state = initialState, action) => {
    let result = {}

    switch (action.type) {
        case "USER_LOGIN":
            result = {...state, ...action.payload}
            break

        case "SITE_THEME":
            result = {...state, theme: {...state.theme, ...action.payload}}
            break
        
        case "SITE_CURRENT_PATH":
            result = {...state, pathname: action.payload}
            break
        
        case "LAYOUT_SCALE":
            result = {...state, layoutScale: {...action.payload}}
            break
        
        case "LAYOUT_USER_LIST":
            result = {...state, userList: (action.payload ? false : !state.userList)}
            break

        default:
            result = state
    }

    setStoreAsync(result)

    return result
}

/**
 * Keep the store in localStorage, so it can continue from where its left before page refresh
 * @param {object} obj The JSON object to parse the store data
 */
async function setStoreAsync(obj = {}) {
    // Sace store to storage for faster restart
    localStorage.setItem('store', JSON.stringify(obj))
}

export default reducer
