// Helper Modules
import recaptcha from '../helper/ReCaptcha'

// React Base Component
import React from 'react'

// Router components
import { Link } from 'react-router-dom'

// Redux store connection
import { connect } from 'react-redux'
import { loginHandle } from '../helper/ReduxActions'

// Reactstrap Components
import {
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert
} from 'reactstrap'

// Custom Components
import Loader from '../global/Loader'

class LoginForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            uname: "",
            passwd: "",
            modal: false,
            resetUname: "",
            loader: false,
            alert: {
                isOpen: false,
                content: false,
                error: false
            },
            alertModal: {
                isOpen: false,
                content: false,
                error: false
            }
        }

        this.onLogin = (event) => {
            event.preventDefault()

            recaptcha.getReCaptchaToken(token => {
                fetch('/api/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        gToken: token,
                        uname: this.state.uname,
                        passwd: this.state.passwd
                    })
                }).then(res => {
    
                    if (res.ok) {
                        return res.json()
                    } else {
                        throw new Error('Login error')
                    }
    
                }).then(data => {
                    this.props.loginHandle({
                        uname: data.name,
                        fullname: data.fullname,
                        role: data.role,
                        token: data.token,
                        login: true
                    })
                }).catch(err => {
                    this.setState({
                        alert: {
                            content: "Giriş işlemi başarısız, kullanıcı adı veya şifre hatalı.",
                            isOpen: true,
                            error: true
                        }
                    })
    
                    console.error(err.message)
                })
            })
        }

        this.onModal = (event) => {
            this.setState({ modal: !this.state.modal })
        }

        this.dismissAlert = () => {
            this.setState({
                alert: {
                    isOpen: false,
                    content: false,
                    error: false
                }
            })
        }
        
        this.sendMail = (event) => {

            this.setState({
                loader: true
            })
            recaptcha.getReCaptchaToken(token => {
                fetch('/api/forget-password/email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ 
                        uname: this.state.resetUname,
                        gToken: token
                     })
                }).then(res => {
                    this.onModal()
    
                    if (res.ok) {
                        this.setState({
                            alert: {
                                content: "Mail başarı ile gönderildi lütfen mail adresinizi kontrol ediniz.",
                                isOpen: true,
                                error: false
                            },
                            loader: false
                        })
                        return res.json()
                    } else {
                        this.setState({
                            alert: {
                                content: "Mail gönderilemedi!",
                                isOpen: true,
                                error: true
                            },
                            loader: false
                        })
                        throw new Error('Login error')
                    }
                }).catch(err => {
                    console.error(err.message)
                })

            })
        }
    }

    render() {
        return (
            <Form id="login-form" onSubmit={this.onLogin}>
                <FormGroup>
                    <Label>Kullanıcı adı</Label>
                    <Input type="text" placeholder="Kullanıcı adı" value={this.state.uname} onChange={(event) => this.setState({ uname: event.target.value })} />
                </FormGroup>
                <FormGroup>
                    <Label>Şifre</Label>
                    <Input type="password" placeholder="Şifre" value={this.state.passwd} onChange={(event) => this.setState({ passwd: event.target.value })} />
                </FormGroup>
                <div className="text-right pb-3 px-1">
                    <Link className="text-dark" onClick={this.onModal}>Şifremi unuttum</Link>
                </div>
                <Row>
                    <Col xs="6">
                        <Button color="dark" outline size="block" tag={Link} to="/register">Kaydol</Button>
                    </Col>
                    <Col xs="6">
                        <Button color="dark" form="login-form" size="block">Giriş</Button>
                    </Col>
                </Row>
                <Modal isOpen={this.state.modal} toggle={!this.state.modal} >
                    <ModalHeader>Şifremi Unuttum</ModalHeader>
                    <ModalBody>
                        <Label>Kullanıcı adı</Label>
                        <Input type="text" placeholder="Kullanıcı adı" value={this.state.resetUname} onChange={(event) => this.setState({ resetUname: event.target.value })} />
                        <Row>
                            {this.state.loader ? <Loader /> : <br></br> }
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.sendMail} toggle={!this.state.modal}>Mail Gönder</Button>
                        <Button color="secondary" onClick={this.onModal} toggle={!this.state.modal}>İptal</Button>
                    </ModalFooter>
                </Modal>
                <Row>
                   <Col className="pt-2" style={{ minHeight: "8rem" }}>
                        <Alert color={this.state.alert.error ? 'danger' : 'success'} isOpen={this.state.alert.isOpen} toggle={this.dismissAlert}>
                            {this.state.alert.content}
                        </Alert>
                    </Col> 
                </Row>
            </Form>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        // empty
    }
}

const mapDipatchToProps = {
    loginHandle,
}

export default connect(mapStateToProps, mapDipatchToProps)(LoginForm)
