// React Base Component
import React from 'react'

//Custom Compoenets
import Loader from '../global/Loader'
import Header from '../global/Header'
import recaptcha from '../helper/ReCaptcha'

//Reactstrap Moudule
import {
    Button,
    Row,
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    Form,
    FormGroup,
    Container,
    Label
} from 'reactstrap'

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFacebook,
    faInstagram,
    faLinkedin,
    faTwitter,
    faYoutube
} from '@fortawesome/free-brands-svg-icons'

// CSS Module
import styles from './modules/Settings.module.css'

class Settings extends React.Component {
    constructor(props) {
        super(props)

        this.members = {
            current: [],
            candidate: []
        }

        this.inputs = {
            description: "",
            instagram: "",
            facebook: "",
            youtube: "",
            twitter: "",
            linkedin: ""
        }

        this.fetchResult = {
            loading: false,
            failed: false,
            failMessage: "",
        }

        this.state = {
            loading: false,
            failed: false,
            failMessage: "",
            
            member: "",
            memberRole: "",
            memberCheck: false,
            memberRoleCheck: false,

            members: JSON.parse(JSON.stringify(this.members)),
            inputs:  JSON.parse(JSON.stringify(this.inputs)),

            initial: {
                inputs: {},
                members: {}
            }
        }

        this.equals = (o1, o2) => {
            return JSON.stringify(o1) === JSON.stringify(o2)
        }
        
        this.resetMembers = () => {
            this.setState({ members: JSON.parse(JSON.stringify(this.state.initial.members)) })
        }

        this.resetInputs = () => {
            this.setState({ inputs: JSON.parse(JSON.stringify(this.state.initial.inputs)) })
        }

        this.memberCompare = (m1, m2) => {
            const nameA = m1.uname.toUpperCase()
            const nameB = m2.uname.toUpperCase()

            return (nameA < nameB) ? -1 : ((nameA > nameB) ? 1 : 0)
        }
        
        this.getCurrentSettings = () => {
            const result = {...this.fetchResult}

            this.setState({ loading: true }, () => {
                fetch('/api/manage/settings').then(res => {
                    if (res.ok) return res.json()
                    throw new Error("Cannot GET site settings")
                }).then(data => {
                    const members = JSON.parse(JSON.stringify(this.members))
                    const inputs  = JSON.parse(JSON.stringify(this.inputs))
                    
                    members.current   = data.current.sort(this.memberCompare)
                    members.candidate = data.candidate.sort(this.memberCompare)

                    inputs.description = data.description || ""
                    inputs.instagram   = data.instagram   || ""
                    inputs.facebook    = data.facebook    || ""
                    inputs.youtube     = data.youtube     || ""
                    inputs.twitter     = data.twitter     || ""
                    inputs.linkedin    = data.linkedin    || ""

                    this.setState({
                        initial: {
                            members: JSON.parse(JSON.stringify(members)),
                            inputs:  JSON.parse(JSON.stringify(inputs))
                        },
                        members: members,
                        inputs: inputs
                    })
                }).catch(err => {
                    result.failed = true
                    result.failMessage = err.message
                }).finally(() => {
                    this.setState({ ...result })
                })
            })
        }

        this.addMember = () => {
            const update  = JSON.parse(JSON.stringify(this.state))
            const members = update.members
            const index   = members.candidate.findIndex(item => item.uname === update.member)



            if (update.memberRole ? false : true) {
                update.memberRoleCheck = true
            } else {
                if (0 <= index) {
                    members.current.push({...members.candidate[index], role: update.memberRole})
                    members.current.sort(this.memberCompare)
                    members.candidate.splice(index, 1)
                    
                    update.memberRole = ""
                    update.member = "none"
                } else {
                    update.memberCheck = true
                }
            }

            this.setState(update)
        }
        
        this.removeMember = (uname) => {
            const update  = JSON.parse(JSON.stringify(this.state))
            const members = update.members
            const index   = members.current.findIndex(item => item.uname === uname)
    
            if (0 <= index) {
                members.candidate.push({...members.current[index]})
                members.candidate.sort(this.memberCompare)
                members.current.splice(index, 1)

                this.setState(update)
            }
        }

        this.handleInputChange = (event) => {
            const update  = JSON.parse(JSON.stringify(this.state))
            update.memberCheck = false
            update.inputs[event.target.name] = event.target.value
            this.setState(update)
        }

        this.handleManagerSubmit = (event) => {
            event.preventDefault()

            const result = {...this.fetchResult}
            const update = {}

            update.list = JSON.parse(JSON.stringify(this.state.members)).current.map(item => ({ uname: item.uname, role: item.role }))

            delete update.list.candidate
            
            // Request to save changes
            this.setState({ loading: true }, () => {
                recaptcha.getReCaptchaToken(token => {
                    update.gToken = token

                    fetch('/api/manage/settings/manager', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(update)
                    }).then(res => {
                        if (res.ok) {
                            this.getCurrentSettings()
                        } else {
                            throw new Error("Failed to save changes")
                        }
                    }).catch(err => {
                        result.failed = true
                        result.failMessage = err.message
                        this.setState({ ...result })
                    })
                })
            })
        }

        this.handleSocialSubmit = (event) => {
            event.preventDefault()

            const result = {...this.fetchResult}
            const update = JSON.parse(JSON.stringify(this.state.inputs))

            // Map "" to null
            for (const key in update) {
                if (Object.hasOwnProperty.call(update, key)) {
                    update[key] = update[key] || null
                }
            }

            // Request to save changes
            this.setState({ loading: true }, () => {
                recaptcha.getReCaptchaToken(token => {
                    update.gToken = token

                    fetch('/api/manage/settings/social', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(update)
                    }).then(res => {
                        if (res.ok) {
                            this.getCurrentSettings()
                        } else {
                            throw new Error("Failed to save changes")
                        }
                    }).catch(err => {
                        result.failed = true
                        result.failMessage = err.message
                        this.setState({ ...result })
                    })
                })
            })
        }
    }

    componentDidMount() {
        this.getCurrentSettings()
    }

    render() {
        const textClassName = ["input-group-text", styles.SocialIcon].join(" ")

        const currentMembers   = this.state.members.current   || []
        const candidateMembers = this.state.members.candidate || []
        const inputs           = this.state.inputs

        return (
            <Container fluid>
                <Row>
                    <Col>
                        <Header value="Site Ayarları" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.state.loading ? <Loader /> : (
                            this.state.failed ? this.state.failMessage : (
                                <div>
                                    <Form id="members" onSubmit={this.handleManagerSubmit}>
                                        <FormGroup>
                                            <Label>Klüp Yönetimi</Label>
                                            {(0 < currentMembers.length) ? (
                                                currentMembers.map((element, index) => (
                                                    <InputGroup key={index} className="mt-1">
                                                        <Input type="text" value={element.name} disabled readOnly></Input>
                                                        <InputGroupAddon addonType="append">
                                                            <Button color="dark" onClick={() => this.removeMember(element.uname)}>Sil</Button>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                ))
                                            ):(
                                                <div><em className="text-muted">Henüz yönetici yok</em></div>
                                            )}
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Yönetici Ekle</Label>
                                            <Input type="select" name="member" value={this.state.member} onChange={(event) => this.setState({ member: event.target.value, memberCheck: false })} invalid={this.state.memberCheck}>
                                                <option value="none" hidden></option>
                                                {candidateMembers.map((element, index) => (
                                                    <option key={index} value={element.uname}>{element.name}</option>
                                                ))}
                                            </Input>
                                            <InputGroup className="mt-1">
                                                <Input type="text" name="memberRole" placeholder="Açıklama" value={this.state.memberRole} onChange={(event) => this.setState({ memberRole: event.target.value, memberRoleCheck: false })} invalid={this.state.memberRoleCheck} />
                                                <InputGroupAddon addonType="append">
                                                    <Button color="dark" onClick={this.addMember}>Ekle</Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                        {this.equals(this.state.members, this.state.initial.members) ? null : (
                                            <FormGroup className="d-flex">
                                                <div className="flex-grow-1 flex-shrink-0"></div>
                                                <Button color="dark" outline onClick={this.resetMembers}>iptal</Button>
                                                <Button color="dark" className="ml-1" form="members">Kaydet</Button>
                                            </FormGroup>
                                        )}
                                    </Form>
                                    <hr/>
                                    <Form id="inputs" onSubmit={this.handleSocialSubmit}>
                                        <FormGroup>
                                            <Label for="description">Açıklama</Label>
                                            <Input type='textarea' placeholder="Özet Bilgi" onChange={this.handleInputChange} value={inputs.description} name="description" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Sosyal Media</Label>
                                            <InputGroup className="mt-1">
                                                <InputGroupAddon addonType='prepend'>
                                                    <div className={textClassName}>
                                                        <FontAwesomeIcon icon={faInstagram} />
                                                    </div>
                                                </InputGroupAddon>
                                                <Input type='text' onChange={this.handleInputChange} value={inputs.instagram} name="instagram" placeholder="<instagram>" />
                                            </InputGroup>
                                            <InputGroup className="mt-1">
                                                <InputGroupAddon addonType='prepend'>
                                                    <div className={textClassName}>
                                                        <FontAwesomeIcon icon={faFacebook} />
                                                    </div>
                                                </InputGroupAddon>
                                                <Input type='text' onChange={this.handleInputChange} value={inputs.facebook} name="facebook" placeholder="<facebook>" />
                                            </InputGroup>
                                            <InputGroup className="mt-1">
                                                <InputGroupAddon addonType='prepend'>
                                                    <div className={textClassName}>
                                                        <FontAwesomeIcon icon={faYoutube} />
                                                    </div>
                                                </InputGroupAddon>
                                                <Input type='text' onChange={this.handleInputChange} value={inputs.youtube} name="youtube" placeholder="<youtube>" />
                                            </InputGroup>
                                            <InputGroup className="mt-1">
                                                <InputGroupAddon addonType='prepend'>
                                                    <div className={textClassName}>
                                                        <FontAwesomeIcon icon={faTwitter} />
                                                    </div>
                                                </InputGroupAddon>
                                                <Input type='text' onChange={this.handleInputChange} value={inputs.twitter} name="twitter" placeholder="<twitter>" />
                                            </InputGroup>
                                            <InputGroup className="mt-1">
                                                <InputGroupAddon addonType='prepend'>
                                                    <div className={textClassName}>
                                                        <FontAwesomeIcon icon={faLinkedin} />
                                                    </div>
                                                </InputGroupAddon>
                                                <Input type='text' onChange={this.handleInputChange} value={inputs.linkedin} name="linkedin" placeholder="<linkedin>" />
                                            </InputGroup>
                                        </FormGroup>
                                        {this.equals(this.state.inputs, this.state.initial.inputs) ? null : (
                                            <FormGroup className="d-flex">
                                                <div className="flex-grow-1 flex-shrink-0"></div>
                                                <Button outline onClick={this.resetInputs}>iptal</Button>
                                                <Button className="ml-1" form="inputs">Kaydet</Button>
                                            </FormGroup>
                                        )}
                                    </Form>
                                </div>
                            )
                        )}
                    </Col>
                    <Col xs="12" xl="6">{/*Spacer*/}</Col>
                </Row>
            </Container>
        )
    }
}

export default Settings
