// React Base Component
import React from 'react'

// Redux store connection
import { connect } from 'react-redux'
import { updateCurrentURL, loginHandle } from '../helper/ReduxActions'

// React Router Components
import {
    Redirect,
    Route
} from 'react-router-dom'

// The react-router-dom Route with connection to the Redux store
class ReduxRoute extends Route {
    constructor(props) {
        super(props)

        this.loginNeedLookup = [
            '/new-blog',
            '/new-annc',
            '/profile',
            '/manage',
            '/settings'
        ]
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location) {
            // Check if the requested url needs a valid token, updat store.login to redirect 
            if (this.loginNeedLookup.indexOf(this.props.location.pathname) != -1) {
                fetch('/api/login').then(res => {
                    if (!res.ok) {
                        this.props.loginHandle({ name: undefined, role: 0, login: false })
                    }
                })
            }
    
            // Update the current pathname in store
            this.props.updateCurrentURL(this.props.location.pathname)
        }
    }

    render() {
        return (
            <Route {...this.props} />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        pathname: state.pathname
    }
}

const mapDipatchToProps = {
    loginHandle,
    updateCurrentURL,
}

export default connect(mapStateToProps, mapDipatchToProps)(ReduxRoute)
