//React Component
import React from 'react'

//React Router Dom Component
import { Link } from 'react-router-dom'

//FontAwsome Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'

class Success extends React.Component {
    render() {
        return (
            <div className="p-3">
                <h1 className="d-flex justify-content-center align-items-center mb-0 text-success" style={{ height: "250px" }}><FontAwesomeIcon icon={faCheckCircle} /> </h1>
                {this.props.blog ? (
                    <div>
                        <h3 className="d-flex justify-content-center text-center text-success">Blog başarılı bir şekilde onaylanmak üzere gönderildi!</h3>
                        <p className="text-center mt-5" style={{ fontSize: "25px" }}>Bloglara<Link to="/blogs"> geri</Link> dön</p>
                    </div>
                ) : (
                    <div>
                        <h3 className="d-flex justify-content-center text-center text-success">Etlinlik başarılı bir şekilde paylaşıldı.</h3>
                        <p className="text-center mt-5" style={{ fontSize: "25px" }}>Etkinliklere<Link to="/announcements"> geri</Link> dön</p>
                    </div>
                )}
            </div>
        )
    }
}

export default Success