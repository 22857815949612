// React Base Component
import React from 'react'

// CSS Module
import styles from './modules/Avatar.module.css'

// React Base Component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPencilAlt
} from '@fortawesome/free-solid-svg-icons'

// Custom Components
import ProfileEditor from './ProfileEditor'

class Avatar extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            modal: false
        }

        this.toggleModal = (event) => {
            this.setState({ modal: !this.state.modal })
        } 
        
        this.openModal = (event) => {
            this.setState({ modal: true })
        }
    }

    render() {
        return (
            <div className={styles.Container} style={{ pointerEvents: (this.props.own ? "unset" : "none") }}>
                <div className={this.props.edit ? styles.ModalAvatar : styles.Avatar} style={{ backgroundImage: this.props.avatar ? `url('${this.props.avatar}')` : `url('${this.props.data.avatar}')` }} />
                {this.props.own ? (
                    <div className={styles.Overlay} onClick={this.openModal}>
                        <FontAwesomeIcon icon={faPencilAlt} size="3x" />
                        <ProfileEditor isOpen={this.state.modal} toggle={this.toggleModal} data={this.props.data} fetchProfileData={this.props.fetchProfileData} />
                    </div>
                ):(null)}
            </div>
        )
    }
}

export default Avatar
