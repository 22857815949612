// React Base Component
import React from 'react'

// React Base Component
import {
    Link,
    Redirect,
    useLocation
} from 'react-router-dom'

// Reactstrap Components
import {
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Alert
} from 'reactstrap'

//Custom Components
import recaptcha from '../helper/ReCaptcha'

class ResetPasswordForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            uname: "",
            passwd: "",
            passwdCheck: "",
            key: undefined,
            correctPasswd: false,
            warningConfPasswd: false,
            alert: {
                isOpen: false,
                content: false,
                error: false
            }
        }

        this.style = {
            button: {
                backgroundColor: "#231f20",
                border: "none"
            }
        }

        this.onReset = (event) => {
            event.preventDefault()
            this.setState({
                alert: {
                    content: false,
                    isOpen: false,
                    error: false
                }
            })

            const update = JSON.parse(JSON.stringify(this.state))

            if (!(update.passwd.length < 6) && !(update.passwdCheck.length < 6)) {
                recaptcha.getReCaptchaToken(token => {
                    fetch('/api/forget-password', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ 
                            uname: this.state.uname, 
                            key: this.state.key, 
                            passwd: this.state.passwd, 
                            gToken: token})
                    }).then(res => {
                        if (res.ok) {
                            this.dismissAlert()
                            this.setState({
                                alert: {
                                    content: "Şifreniz başarılı bir şekilde değişti, giriş yapabilirsiniz.",
                                    isOpen: true,
                                    error: false
                                }
                            })
                        } else {
                            throw new Error('Reset Password Error')
                        }
                    }).catch(err => {
                        this.setState({
                            alert: {
                                content: "Şifre değiştirilemedi, kullanıcı adı veya kod hatalı.",
                                isOpen: true,
                                error: true
                            }
                        })
                        console.error(err.message)
                    })
                })
            } else {
                this.setState({ correctPasswd: false, warningConfPasswd: true })
            }
        }

        this.dismissAlert = () => {
            this.setState({
                alert: {
                    isOpen: false,
                    content: false,
                    error: false
                }
            })
        }

        this.handlePasswd = () => {

            if (this.state.passwd == "" || this.state.passwdCheck == "") {
                this.setState({ correctPasswd: false, warningConfPasswd: false })
            } else {
                if (this.state.passwdCheck == this.state.passwd) {
                    this.setState({ correctPasswd: true, warningConfPasswd: false })
                } else {
                    this.setState({ correctPasswd: false, warningConfPasswd: true })
                }
            }

        }
    }

    render() {
        return (
            <Form id="reset-form" onSubmit={this.onReset}>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Kullanıcı Adı</Label>
                            <Input type="text" placeholder="Kullanıcı Adı" value={this.state.uname} onChange={(event) => this.setState({ uname: event.target.value })} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Yeni Şifre</Label>
                            <Input valid={this.state.correctPasswd} invalid={this.state.warningConfPasswd} onBlur={() => { this.handlePasswd() }} type="password" placeholder="Yeni Şifre" value={this.state.passwd} onChange={(event) => this.setState({ passwd: event.target.value })} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Yeni Şifre Tekrar</Label>
                            <Input valid={this.state.correctPasswd} invalid={this.state.warningConfPasswd} onBlur={() => { this.handlePasswd() }} type="password" placeholder="Yeni Şifre Tekrar" value={this.state.passwdCheck} onChange={(event) => this.setState({ passwdCheck: event.target.value })} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Kodu Giriniz</Label>
                            <Input type="text" placeholder="Kod" value={this.state.key} onChange={(event) => this.setState({ key: event.target.value })} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6">
                        <Button color="dark" form="reset-form" size="block" style={this.style.button}>Şifremi Değiştir</Button>
                    </Col>
                    <Col xs="6">
                        <Button color="dark" size="block" style={this.style.button} tag={Link} to="/login">Giriş Yap</Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="pt-3" style={{ minHeight: "7rem" }}>
                        <Alert color={(this.state.alert.error) ? 'danger' : 'success'} isOpen={this.state.alert.isOpen} toggle={this.dismissAlert}>
                            {this.state.alert.content}
                        </Alert>
                    </Col>
                </Row>
            </Form>
        )
    }
}

export default ResetPasswordForm
