// React Base Component
import React from 'react'

// Redux store connection
import { connect } from 'react-redux'

// FontAwesome Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

// Reactstrap Components
import {
    Table
} from 'reactstrap'

// CSS Module
import styles from './modules/TableList.module.css';

class TableList extends React.Component {
    render() {
        return (
            <Table hover striped bordered responsive size="sm">
                <thead>
                    <tr>
                        {this.props.headers.map((header, index) => (
                            <th key={index}>
                                <div className={[styles.Sort, "text-center rounded-pill py-2"].join(" ")} onClick={() => this.props.sort({ index: index, order: !this.props.sortParams.order })}>
                                    {this.props.sortParams.index === index ? <FontAwesomeIcon icon={this.props.sortParams.order ? faCaretUp : faCaretDown} /> : null} {header}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {this.props.data.map((row, index) => (
                        <tr key={index} className={styles.Row} onClick={() => this.props.edit(index)}>{row.map((col, index) => (
                            <td key={index} className={styles.Col}>
                                <div className={styles.Content}>{col}</div>
                            </td>
                        ))}</tr>
                    ))}
                </tbody>
            </Table>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        width: state.layoutScale.width,
        height: state.layoutScale.height
    }
}

const mapDipatchToProps = {
}

// To scale the table, not used for now
export default connect(mapStateToProps, mapDipatchToProps)(TableList)
