// React Base Component
import React from 'react'

// Reduc Connection
import { connect } from 'react-redux'

// Reactstrap Components
import {
    Button,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap'

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons'
import {
    faFacebook,
    faGithubAlt,
    faInstagram,
    faLinkedin,
    faTwitter
} from '@fortawesome/free-brands-svg-icons'

//Custom Components
import recaptcha from '../helper/ReCaptcha'

// CSS Custom Components
import Avatar from './Avatar'
import Loader from '../global/Loader'

// CSS Module
import styles from './modules/ProfileEditor.module.css'

class ProfileEditor extends Modal {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            avatar: this.props.data.avatar,
            description: this.props.data.description,
            ...this.props.data.social,
            isOpen: false
        }

        this.fileRef = React.createRef()

        this.handleAvatarChange = (event) => {
            const formData = new FormData()
            formData.append('img', event.target.files[0])
            formData.append('old', this.state.avatar)

            this.setState({ loading: true }, () => {
                recaptcha.getReCaptchaToken(token => {
                    formData.append("gToken", token)

                    fetch('/api/media/upload/avatar', {
                        method: 'POST',
                        body: formData
                    }).then(res => {
                        if (!res.ok) {
                            throw new Error('Failed to upload avatar')
                        } else {
                            this.props.fetchProfileData()
                        }
                    }).catch(err => {
                        console.error(err.message)
                    }).finally(() => {
                        this.setState({ loading: false, isOpen: false })
                    })
                })
            })
        }

        this.handleButtonClick = (event) => {
            this.fileRef.current.click()
        }

        this.handleChange = (event) => {
            const update = { ...this.state }
            update[event.target.name] = event.target.value
            this.setState(update)
        }

        this.handleSubmit = (event) => {
            event.preventDefault()
            recaptcha.getReCaptchaToken(token => {
                fetch('/api/profile/update', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ...this.state,
                        description: this.state.description,
                        avatar: this.state.avatar,
                        password: ((((this.state.password || "").length >= 6) && (this.state.password === this.state.passwordConfirm)) ? this.state.password : null),
                        passwordConfirm: undefined,
                        passwordInvalid: undefined,
                        gToken: token
                    })
                }).then(res => {
                    if (res.ok) {
                        this.props.fetchProfileData()
                    } else {
                        throw new Error('Cannot POST /profile')
                    }
                }).catch(err => {
                    console.error(err.message)
                })
            })
        }

        this.setMediaItem = (media) => {
            this.setState({ avatar: media, isOpen: false })

        }

        this.checkPassword = () => {
            if (((this.state.password || "").length < 6) || (this.state.password !== this.state.passwordConfirm)) {
                this.setState({ passwordInvalid: true })
            } else {
                this.setState({ passwordInvalid: false })
            }
        }
    }

    render() {
        const textClassName = ["input-group-text", styles.Text].join(" ")

        return (
            <Modal {...this.props}>
                <ModalHeader>Profili Düzenle</ModalHeader>
                <ModalBody>
                    {this.state.loading ? (
                        <Loader />
                    ) : (
                        <Form onSubmit={this.handleSubmit} id="edit-profile">
                            <FormGroup>
                                <Label>Profil resmi ekle</Label>
                                <Avatar edit avatar={this.state.avatar} />
                                <input type="file" ref={this.fileRef} onChange={this.handleAvatarChange} hidden />
                                <Button color="dark" className="mt-3" outline onClick={this.handleButtonClick}>
                                    {this.state.avatar ? "Değiştir" : "Ekle"}
                                </Button>
                            </FormGroup>
                            <FormGroup>
                                <Label for="description">Açıklama</Label>
                                <Input type='textarea' placeholder="Özet Bilgi" onChange={this.handleChange} value={this.state.description} name="description" />
                            </FormGroup>
                            <FormGroup>
                                <Label>Sosyal Medya</Label>
                                <InputGroup className="mt-1">
                                    <InputGroupAddon addonType='prepend'>
                                        <div className={textClassName}>
                                            <FontAwesomeIcon icon={faInstagram} />
                                        </div>
                                    </InputGroupAddon>
                                    <Input type='text' onChange={this.handleChange} value={this.state.instagram} name="instagram" placeholder="<instagram>" />
                                </InputGroup>
                                <InputGroup className="mt-1">
                                    <InputGroupAddon addonType='prepend'>
                                        <div className={textClassName}>
                                            <FontAwesomeIcon icon={faFacebook} />
                                        </div>
                                    </InputGroupAddon>
                                    <Input type='text' onChange={this.handleChange} value={this.state.facebook} name="facebook" placeholder="<facebook>" />
                                </InputGroup>
                                <InputGroup className="mt-1">
                                    <InputGroupAddon addonType='prepend'>
                                        <div className={textClassName}>
                                            <FontAwesomeIcon icon={faTwitter} />
                                        </div>
                                    </InputGroupAddon>
                                    <Input type='text' onChange={this.handleChange} value={this.state.twitter} name="twitter" placeholder="<twitter>" />
                                </InputGroup>
                                <InputGroup className="mt-1">
                                    <InputGroupAddon addonType='prepend'>
                                        <div className={textClassName}>
                                            <FontAwesomeIcon icon={faLinkedin} />
                                        </div>
                                    </InputGroupAddon>
                                    <Input type='text' onChange={this.handleChange} value={this.state.linkedin} name="linkedin" placeholder="<linkedin>" />
                                </InputGroup>
                                <InputGroup className="mt-1">
                                    <InputGroupAddon addonType='prepend'>
                                        <div className={textClassName}>
                                            <FontAwesomeIcon icon={faGithubAlt} />
                                        </div>
                                    </InputGroupAddon>
                                    <Input type='text' onChange={this.handleChange} value={this.state.github} name="github" placeholder="<github>" />
                                </InputGroup>
                                <InputGroup className="mt-1">
                                    <InputGroupAddon addonType='prepend'>
                                        <div className={textClassName}>
                                            <FontAwesomeIcon icon={faGlobeAmericas} />
                                        </div>
                                    </InputGroupAddon>
                                    <Input type='text' onChange={this.handleChange} value={this.state.website} name="website" placeholder="<website>" />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label>Şifre Değiştir</Label>
                                <Input
                                    className="mb-2"
                                    invalid={this.state.passwordInvalid}
                                    type="password"
                                    name="password"
                                    placeholder="Yeni şifre"
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    onBlur={this.checkPassword}
                                    autoComplete="new-password"
                                />
                                <Input
                                    invalid={this.state.passwordInvalid}
                                    type="password"
                                    name="passwordConfirm"
                                    placeholder="Şifre onayı"
                                    value={this.state.passwordConfirm}
                                    onChange={this.handleChange}
                                    onBlur={this.checkPassword}
                                    autoComplete="new-password"
                                />
                            </FormGroup>
                        </Form>
                    )}
                </ModalBody>
                <ModalFooter>
                    {this.state.loading ? (
                        "Resim yükleniyor..."
                    ) : (
                        [
                            <Button color="dark" key="0" color='dark' outline onClick={this.props.toggle}>İptal</Button>,
                            <Button color="dark" key="1" color='dark' form="edit-profile">Kaydet</Button>
                        ]
                    )}
                </ModalFooter>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        pathname: state.pathname
    }
}

const mapDipatchToProps = {
    // empty
}

export default connect(mapStateToProps, mapDipatchToProps)(ProfileEditor)
