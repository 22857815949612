// React Base Component
import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faLaughBeam, faHeart } from '@fortawesome/free-regular-svg-icons'

// Reactstrap Components
import {
    Col,
    Row
} from 'reactstrap'

//Custom components
import recaptcha from '../../helper/ReCaptcha'

import styles from './modules/Reaction.module.css'
class Reactions extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            commentID: this.props.commentID,
            reactionID: this.props.reactionObj.includesMyReaction,
            reactionArr: [
                !this.props.reactionObj.reaction1 ? 0 : this.props.reactionObj.reaction1,
                !this.props.reactionObj.reaction2 ? 0 : this.props.reactionObj.reaction2,
                !this.props.reactionObj.reaction3 ? 0 : this.props.reactionObj.reaction3,
            ],
            currentReaction: this.props.reactionObj.includesMyReaction,
            includesMyReaction: this.props.reactionObj.includesMyReaction ? 1 : 0,
        }

        this.decreaseReactionNum = (index, reactionArr) => {
            let dummyReaionctArr = reactionArr || [...this.state.reactionArr]
            dummyReaionctArr[index - 1] = dummyReaionctArr[index - 1] - 1
            this.setState({ reactionArr: dummyReaionctArr })

        }

        this.increaseReactionNum = (index) => {
            let dummyReaionctArr = [...this.state.reactionArr]
            dummyReaionctArr[index - 1] = dummyReaionctArr[index - 1] + 1
            if (this.state.currentReaction != index && this.state.currentReaction != 0) {
                this.decreaseReactionNum(this.state.currentReaction, dummyReaionctArr)
            } else {
                this.setState({ reactionArr: dummyReaionctArr })
            }
            this.setState({ currentReaction: index })

        }

        this.tiklasanaBana = (index) => {
            if (!this.state.break) {
                // this.state.reactionID ? (this.increaseReactionNum(index)) : (this.decreaseReactionNum(index))
                this.setState({ reactionID: (this.state.reactionID == index ? 0 : index), currentReaction: (this.state.reactionID ? this.state.currentReaction : 0) }, () => {
                    const { commentID, reactionID, includesMyReaction } = this.state
                    const postCommentObj = { commentID, reactionID, includesMyReaction}

                    this.setState({ break: true, includesMyReaction: (this.state.reactionID ? 1 : 0) }, () => {
                        recaptcha.getReCaptchaToken(token => {
                            postCommentObj.gToken = token

                            fetch('/api/reaction', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(postCommentObj)
                            }).then(res => {
                                setTimeout(() => {
                                    this.setState({ break: false })
                                }, 1000)
                                if (res.ok) return res.json()
                                throw new Error('Can not fetch the reaction!')
                            }).then(data => {
                                // empty
                            }).catch(err => {
                                console.error(err.message)
                            })
                        })
                    })
                })
            } else {
                alert("Lütfen biraz zaman geçmesini bekleyin.")
            }
        }
    }

    render() {
        return (

            <div xs="6" className={styles.UserListItem} >
                <Row className="align-items-center text-secondary" noGutters>
                    {!this.props.reply ? [
                        <Col key="1" xs="auto" >
                            <div className={[styles.ReplyButtonText, styles.ReactionButtonText, "px-1 my-2 rounded-lg"].join(" ")}
                                onClick={this.props.setShowState}>Cevapla</div>
                        </Col>,
                        <Col key="2" xs="auto" className="px-1 py-0" style={{ fontWeight: "100" }}>
                            |
                        </Col>
                    ] : undefined}
                    <Col xs="auto" className="pr-2">
                        <div className={[styles.LikeButtonText, styles.ReactionButtonText, "px-1 my-2 rounded-lg"].join(" ")}
                            onClick={() => this.tiklasanaBana(1)} style={{
                                color: (this.state.reactionID ? "#2d86ff" : "#65676b")
                            }}>Beğen</div>
                    </Col>
                    <Col xs="auto" >
                        <div className={["mb-0 p-1 text-secondary text-center rounded-circle", styles.ReactionButton].join(" ")} onClick={() => this.tiklasanaBana(1)}>
                            <FontAwesomeIcon className={styles.LikeIcon} style={{ color: this.state.reactionID === 1 ? "#2d86ff" : null }}

                                icon={faThumbsUp} />
                            {this.state.reactionArr[0] + (this.state.reactionID == 1 ? 1 : 0)}
                        </div>
                    </Col>
                    <Col xs="auto" className="pl-2 pr-2">
                        <div className={["mb-0 p-1 text-secondary text-center rounded-circle", styles.ReactionButton].join(" ")} onClick={() => this.tiklasanaBana(2)}>
                            <FontAwesomeIcon className={styles.LaughIcon} style={{ color: this.state.reactionID === 2 ? "#fab005" : "#65676b" }}
                                icon={faLaughBeam} />
                            {this.state.reactionArr[1] + (this.state.reactionID == 2 ? 1 : 0)}
                        </div>
                    </Col>
                    <Col xs="auto" >
                        <div className={["mb-0 p-1 text-secondary text-center rounded-circle", styles.ReactionButton].join(" ")} onClick={() => this.tiklasanaBana(3)}>
                            <FontAwesomeIcon className={styles.HeartIcon} style={{ color: this.state.reactionID === 3 ? "#af0011" : "#65676b" }}
                                icon={faHeart} />
                            {this.state.reactionArr[2] + (this.state.reactionID == 3 ? 1 : 0)}
                        </div>
                    </Col>
                </Row>
            </div>


        )
    }
}

export default Reactions
