// React Base Component
import React from 'react'

// Reactstrap Components
import {
    Button,
    Col,
    CustomInput,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    PopoverBody,
    PopoverHeader,
    Row,
    UncontrolledPopover
} from 'reactstrap'

// FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCaretDown,
    faSearch
} from '@fortawesome/free-solid-svg-icons'

// Custom Components
import TableList from './TableList'
import Loader from '../global/Loader'
import Blogg from '../blog/Blog'
import NewBlog from '../textEditor/NewBlog'
import recaptcha from '../helper/ReCaptcha'

class EditCategories extends Modal {
    constructor(props) {
        super(props)

        this.state = {
            input: "",
            invalid: false,
            loading: false,
            data: []
        }

        this.fetchCategories = () => {
            this.setState({ loading: true }, () => {
                fetch('/api/category').then(res => {
                    if (res.ok) return res.json()
                    throw new Error("Cannot get category list")
                }).then(data => {
                    this.setState({ data })
                }).catch(err => {
                    console.error(err.message)
                }).finally(() => {
                    this.setState({ loading: false })
                })
            })
        }

        this.addCat = () => {
            const update = JSON.parse(JSON.stringify(this.state))

            if (this.state.input) {
                update.data.push(this.state.input)
                update.input = ""
            } else {
                update.invalid = true
            }

            this.setState(update)
        }

        this.rmCat = (value) => {
            const update = JSON.parse(JSON.stringify(this.state))
            const index = update.data.findIndex(item => item === value)

            if (index >= 0) {
                update.data.splice(index, 1)
            }

            this.setState(update)
        }

        this.handleChange = (event) => {
            this.setState({
                input: event.target.value.match(/[a-zA-Z0-9]*/).join(""),
                invalid: false
            })
        }

        this.handleSubmit = () => {
            this.setState({ loading: true }, () => {
                recaptcha.getReCaptchaToken(token => {
                    fetch('/api/category', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(this.state.data.push("gToken", token))
                    }).then(res => {
                        if (res.ok) {
                            this.props.toggle()
                            this.props.update()
                        } else {
                            throw new Error("Cannot get category list")
                        }
                    }).catch(err => {
                        console.error(err.message)
                    }).finally(() => {
                        this.setState({ loading: false })
                    })
                })
            })
        }
    }

    render() {
        return (
            <Modal {...{ ...this.props, update: undefined }} onOpened={this.fetchCategories}>
                <ModalHeader>
                    Kategorileri Düzenle
                </ModalHeader>
                <ModalBody>
                    {this.state.loading ? (
                        <Loader />
                    ) : (
                        <Form id="category-editor" onSubmit={this.handleSubmit}>
                            <FormGroup>
                                <Label>Kategoriler</Label>
                                {this.state.data.map((value, index) => (
                                    <InputGroup key={index} className="my-1">
                                        <Input value={value} disabled readOnly />
                                        <InputGroupAddon addonType="append">
                                            <Button color="dark" onClick={() => this.rmCat(value)}>Sil</Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                ))}
                            </FormGroup>
                            <FormGroup>
                                <Label>Kategori Ekle</Label>
                                <InputGroup className="my-1">
                                    <Input invalid={this.state.invalid} value={this.state.input} onChange={this.handleChange} />
                                    <InputGroupAddon addonType="append">
                                        <Button color="dark" onClick={this.addCat}>Ekle</Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Form>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="dark" outline onClick={this.props.toggle}>İptal</Button>
                    <Button color="dark" form="category-editor">Kaydet</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

class Filter extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            filter: {
                search: "",
                passive: true,
                suspended: true,
                active: true,
                categories: {
                    "Kategorisiz": true
                }
            },
            isOpen: false,
            catIsOpen: false,
            change: false
        }

        this.handleSearchChange = (event) => {
            const update = { ...this.state }
            update.filter[event.target.name] = event.target.value
            update.change = true
            this.setState(update)
        }

        this.handleStatusChange = (event) => {
            const update = { ...this.state }
            update.filter[event.target.name] = event.target.checked
            update.change = true
            this.setState(update)
        }

        this.handleCategoryChange = (event) => {
            const update = { ...this.state }
            update.filter.categories[event.target.name] = event.target.checked
            update.change = true
            this.setState(update)
        }

        this.catToggle = () => {
            this.setState({ catIsOpen: !this.state.catIsOpen })
        }

        this.getCategories = () => {
            fetch('/api/manage/blog/category').then(res => {
                if (res.ok) return res.json()
                throw new Error("Cannot GET blog categories: " + res.status)
            }).then(data => {
                const update = { ...this.state }
                data.forEach(key => {
                    update.filter.categories[key] = true
                })
                this.setState(update)
            }).catch(err => {
                console.error(err.message)
            })
        }
    }

    componentDidMount() {
        this.getCategories()
    }

    componentDidUpdate() {
        if (this.state.change) {
            this.setState({ change: false }, () => {
                this.props.update(this.state.filter)
            })
        }
    }

    render() {
        return (
            <Form className="py-1">
                <EditCategories update={this.getCategories} isOpen={this.state.catIsOpen} toggle={this.catToggle} />
                <Row noGutters>
                    <Col className="flex-grow-1 flex-shrink-0">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Button color="dark" id="filters" type="button" block>
                                    Filtreler <FontAwesomeIcon icon={faCaretDown} />
                                </Button>
                                <UncontrolledPopover trigger="legacy" placement="bottom" isOpen={this.state.isOpen} target="filters" toggle={() => this.setState({ isOpen: !this.state.isOpen })}>
                                    <PopoverHeader>Filtreler</PopoverHeader>
                                    <PopoverBody>
                                        <FormGroup>
                                            <Label>Durum</Label>
                                            <div>
                                                <CustomInput type="checkbox" name="passive" id="passive" label={"Pasif"} checked={this.state.filter.passive} onChange={this.handleStatusChange} />
                                                <CustomInput type="checkbox" name="suspended" id="suspended" label={"Beklemede"} checked={this.state.filter.suspended} onChange={this.handleStatusChange} />
                                                <CustomInput type="checkbox" name="active" id="active" label={"Aktif"} checked={this.state.filter.active} onChange={this.handleStatusChange} />
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Kategori</Label>
                                            <div>
                                                {Object.keys(this.state.filter.categories).map((category, index) => (
                                                    <CustomInput key={index} type="checkbox" name={category} id={category} label={category} checked={this.state.filter.categories[category]} onChange={this.handleCategoryChange} />
                                                ))}
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <Button color="dark" onClick={this.catToggle}>Kategorileri Düzenle</Button>
                                        </FormGroup>
                                    </PopoverBody>
                                </UncontrolledPopover>
                            </InputGroupAddon>
                            <Input type="text" name="search" placeholder="Hızlı Arama" value={this.state.filter.search} onChange={this.handleSearchChange} />
                            <InputGroupAddon addonType="append">
                                <Button color="dark">
                                    <FontAwesomeIcon icon={faSearch} />
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>
            </Form>
        )
    }
}

class ViewBlog extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            editor: false,
            data: {}
        }

        this.setBlogData = (data) => {
            this.setState({ data: data })
        }

        this.setEditorState = () => {
            this.setState({ editor: false })
        }

    }

    componentDidUpdate() {
        if (!this.props.editorIsOpen && this.state.editor) {
            this.setState({ editor: false })
        }
    }
    render() {

        return (
            <Modal {...this.props} className="modal-xl" scrollable>
                <ModalHeader toggle={this.props.toggle}>Blog İçeriği</ModalHeader>
                <ModalBody>
                    {
                        this.state.editor ? (
                            <NewBlog manage hideUserList data={this.state.data} url={this.props.url} setEditorState={this.setEditorState} />
                        ) : (
                            <Blogg manage data={this.props.data} url={this.props.url} setBlogData={this.setBlogData} />
                        )
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="dark" outline onClick={() => this.setState({ editor: !this.state.editor })}>{this.state.editor ? "Geri Dön" : "İçeriği Düzenle"}</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

class Category extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            url: this.props.url,
            categoryLookup: [],
            currentCategories: [],
            difference: undefined,
            category: undefined
        }

        this.handleChange = (event) => {
            this.setState({ category: event.target.value })
        }

        this.insertCategory = () => {
            const { url, category } = this.state
            const insertObj = {
                url,
                category
            }
            recaptcha.getReCaptchaToken(token => {
                insertObj.gToken = token

                fetch('/api/manage/blog/insert-category', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(insertObj)
                }).then(res => {
                    if (res.ok) {
                        this.fetchCategoryData()
                        return res.status
                    } else {
                        throw new Error('Can not insert the category!')
                    }
                }).catch(error => {
                    console.error(error.message)
                })
            })
        }

        this.deleteCategory = (category) => {
            const { url } = this.state
            const deleteObj = {
                url,
                category
            }
            recaptcha.getReCaptchaToken(token => {
                deleteObj.gToken = token

                fetch('/api/manage/blog/delete-category', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(deleteObj)
                }).then(res => {
                    if (res.ok) {
                        this.fetchCategoryData()
                        return res.status
                    } else {
                        throw new Error('Can not delete the category!')
                    }
                }).catch(err => {
                    console.error(err.message)
                })
            })
        }

        this.fetchCategoryData = () => {
            this.setState({ loading: true }, () => {
                fetch('/api/manage/blog/blog-category' + this.state.url).then(res => {
                    if (res.ok) return res.json()
                    throw new Error('Can not get the currentCategories of the blog!')
                }).then(data => {
                    data.categories = data.categories ? data.categories.split(',') : []
                    data.categoryList = data.categoryList ? data.categoryList.split(',') : []
                    let difference = data.categoryList.filter(x => !data.categories.includes(x))

                    this.setState({ categoryLookup: data.categoryList, currentCategories: data.categories, difference: difference, loading: false })
                }).catch(err => {
                    console.error(err.message)
                })
            })
        }

        this.style = {
            button: {
                backgroundColor: "#231f20",
                border: "none"
            }
        }
    }

    componentDidMount() {
        this.fetchCategoryData()
    }
    render() {
        return (
            this.state.loading ? (
                <Loader />
            ) : (
                <FormGroup>
                    {
                        this.state.currentCategories.length > 0 ? (
                            this.state.currentCategories.map(element => (
                                <InputGroup className="mt-1">
                                    <Input disabled type="text" name="category" value={element}></Input>
                                    <InputGroupAddon addonType="append" >
                                        <Button style={{ width: "50px" }} color="secondary" onClick={() => this.deleteCategory(element)}>Sil</Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            ))
                        ) : (
                            <p className="text-muted font-italic">Bu bloga henüz bir kategori atanmamış.</p>
                        )
                    }
                    <InputGroup className="mt-1">
                        <Input type="select" name="member" onChange={this.handleChange} >
                            <option value="" hidden></option>
                            {this.state.difference.map((element, index) => (
                                <option key={index} value={element}>{element}</option>
                            ))}
                        </Input>
                        <InputGroupAddon addonType="append" >
                            <Button style={{ width: "50px" }} color="secondary" onClick={() => this.insertCategory()}>Ekle</Button>
                        </InputGroupAddon>
                    </InputGroup>
                </FormGroup>
            )
        )
    }
}

class Editor extends Modal {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            userList: [],
            categories: this.props.categories
        }

        this.handleChange = (event) => {
            const update = { ...this.state }
            update.data[event.target.name] = event.target.value
            this.setState(update)
        }

        this.handleChangeStatus = (event) => {
            const update = { ...this.state }
            update.data.status = event.target.value
            this.setState(update)
        }

        this.handleChangeURL = (event) => {
            const update = { ...this.state }
            update.data.url = event.target.value.toLowerCase()

            // Turkish char filter
            update.data.url = update.data.url.replace(/ç/g, "c")
            update.data.url = update.data.url.replace(/ğ/g, "g")
            update.data.url = update.data.url.replace(/ı/g, "i")
            update.data.url = update.data.url.replace(/ö/g, "o")
            update.data.url = update.data.url.replace(/ş/g, "s")
            update.data.url = update.data.url.replace(/ü/g, "u")

            // Space to dash
            update.data.url = update.data.url.replace(/\s+/g, "-")
            update.data.url = update.data.url.replace(/\-+/g, "-")

            // Remove non-alphanumerics
            update.data.url = (update.data.url.match(/[A-Za-z0-9\-]/g) || []).join("")

            this.setState(update)
        }

        this.onBlurURL = (event) => {
            const update = { ...this.state }
            update.data.url = update.data.url.replace(/^\-|\-$/g, "")
            this.setState({ invalid: (event.target.value.length < 4) })
        }

        this.handleSubmit = () => {
            recaptcha.getReCaptchaToken(token => {
                fetch('/api/manage/blog', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ...this.state.data,
                        date: undefined,
                        uname: undefined,
                        url: '/' + this.state.data.url,
                        gToken: token
                    })
                }).then(res => {
                    if (res.ok) {
                        this.props.save()
                    } else {
                        throw new Error('Failed to save changes.')
                    }
                }).catch(err => {
                    console.error(err.message)
                })
            })
        }

        this.updateFormFields = () => {
            this.setState({ data: { ...this.props.data, url: this.props.data.url.substr(1) } }, () => {
                fetch('/api/manage/blog/user-list').then(res => {
                    if (res.ok) return res.json()
                    throw new Error('Failed to GET available users.')
                }).then(data => {
                    this.setState({ userList: data })
                }).catch(err => {
                    console.error(err.message)
                })
            })
        }
    }

    componentDdMount() {
        if (this.props.data.url) {
            this.updateFormFields()
        }
    }

    componentDidUpdate(oldProps) {
        if (this.props.data.url && ((oldProps.data.url !== this.props.data.url) || (oldProps.isOpen === false && this.props.isOpen === true))) {
            this.updateFormFields()
        }
    }

    render() {
        return (
            <Modal {...this.props} scrollable>
                <ModalHeader>Blog Ayarları</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            {
                                this.props.loading ? <Loader /> : null
                            }
                            <Label>Durum</Label>
                            <CustomInput
                                type="radio"
                                name="passive"
                                id="passive-select"
                                label={"Pasif"}
                                checked={this.state.data.status == 0}
                                value="0"
                                onChange={this.handleChangeStatus}
                            />
                            <CustomInput
                                type="radio"
                                name="suspended"
                                id="suspended-select"
                                label={"Beklemede"}
                                checked={this.state.data.status == 1}
                                value="1"
                                onChange={this.handleChangeStatus}
                            />
                            <CustomInput
                                type="radio"
                                name="active"
                                id="active-select"
                                label={"Aktif"}
                                checked={this.state.data.status == 2}
                                value="2"
                                onChange={this.handleChangeStatus}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>URL</Label>
                            <Input invalid={this.state.invalid} type="text" name="url" value={this.state.data.url} onChange={this.handleChangeURL} onBlur={this.onBlurURL} />
                            <FormFeedback>URL en az 4 geçerli karakterden oluşmalı</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>Yazar</Label>
                            <Input type="select" name="author" value={this.state.data.author} onChange={this.handleChange}>
                                {this.state.userList.map((element, index) => (
                                    <option key={index} value={element.uname}>{element.name}</option>
                                ))}
                            </Input>
                        </FormGroup>
                        <Label>Kategoriler</Label>
                        <Category url={this.props.data.url} />
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="dark" outline onClick={this.props.toggle}>İptal</Button>
                    <Button color="dark" onClick={this.handleSubmit}>Kaydet</Button>
                    <Button color="dark" onClick={() => (this.props.viewblog())}>Blogu gör</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

class Blog extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            raw: [],
            data: [],
            loading: true,
            failed: false,
            failMessage: "",
            editorIsOpen: false,
            blogIsOpen: false,
            blogData: {},
            editTarget: "",
            sort: {
                index: -1,
                order: false
            },
            categories: []
        }

        this.sort = async (params) => {
            const update = { ...this.state }
            update.sort = { ...params }
            update.data = this.toSortable(JSON.parse(JSON.stringify(update.raw)))

            if (params.index !== -1) {
                update.data.sort((e1, e2) => {
                    if (typeof e1[params.index] === 'string') {
                        return e1[params.index].localeCompare(e2[params.index], "tr") * (params.order ? 1 : -1)
                    } else {
                        return ((e1[params.index] < e2[params.index]) ? -1 : ((e1[params.index] > e2[params.index]) ? 1 : 0)) * (params.order ? 1 : -1)
                    }
                })
            }

            update.data.forEach(element => {
                element[2] = element[2] ? element[2].toLocaleDateString("tr") : null
                element[3] = element[3] ? element[3].toLocaleDateString("tr") : null
            })

            this.setState(update)
        }

        this.filter = async (filters = {}) => {
            if (Object.keys(filters).length == 0) {
                this.setState({ data: Array.from(this.state.raw) })
            } else {
                const update = { ...this.state }

                update.data = this.state.raw.filter(element => {
                    const regexp = new RegExp(filters.search.replace(/(^\s+)|(\s+$)/g, "").replace(/\s+/g, " "), "ig")
                    const search = !filters.search || [element.title, element.author, element.url].join('|').match(regexp) ? true : false

                    const passive = filters.passive ? element.status === 0 : false
                    const suspended = filters.suspended ? element.status === 1 : false
                    const active = filters.active ? element.status === 2 : false

                    let category = false

                    if (element.categories) {
                        Object.keys(filters.categories).forEach(key => {
                            if (filters.categories[key]) {
                                category = category || element.categories.includes(key)
                            }
                        })
                    } else {
                        category = filters.categories["Kategorisiz"]
                    }

                    return search && (passive || suspended || active) && category
                })

                update.data = this.toReadable(update.data)

                this.setState(update)
            }
        }

        this.edit = async (index) => {
            const target = { ...this.state.raw.find((element) => element.url == this.state.data[index][5]) }
            target.author = target.uname
            target.oldUrl = target.url
            this.setState({ editorIsOpen: true, editTarget: target })
        }

        this.save = () => {
            this.setState({ editorIsOpen: false, loading: true }, () => {
                this.fetchUserTable()
            })
        }

        this.toSortable = (array) => {
            const newArray = []
            const statusLookup = ["Pasif", "Beklemede", "Aktif"]

            array.forEach(element => {
                newArray.push([
                    element.title,                                  // title
                    element.author,                                 // autoher
                    element.date ? new Date(element.date) : null,   // date
                    element.edit ? new Date(element.edit) : null,   // last edit
                    statusLookup[element.status],                   // status
                    element.url                                     // url
                ])
            })

            return newArray
        }

        this.toReadable = (array) => {
            const newArray = []
            const statusLookup = ["Pasif", "Beklemede", "Aktif"]

            array.forEach(element => {
                newArray.push([
                    element.title,                                     // title
                    element.author,                                    // autoher
                    (new Date(element.date)).toLocaleDateString("tr"), // date
                    (new Date(element.edit)).toLocaleDateString("tr"), // last edit
                    statusLookup[element.status],                      // status    
                    element.url                                        // url
                ])
            })

            return newArray
        }

        this.fetchUserTable = () => {
            fetch('/api/manage/blog').then(res => {
                if (res.ok) return res.json()
                throw new Error('Cannot GET /api/manage/blog')
            }).then(data => {
                this.setState({ raw: data, data: this.toReadable(data), loading: false })
            }).catch(err => {
                this.setState({ loading: false, failed: true, failMessage: err.message })
                console.error(err.message)
            })
        }

        this.viewBlog = () => {
            this.setState({ blogIsOpen: true })
        }

    }

    componentDidMount() {
        this.fetchUserTable()
    }

    render() {
        return (
            <div>
                <Editor data={this.state.editTarget} categories={this.state.categories} save={this.save} isOpen={this.state.editorIsOpen} toggle={() => this.setState({ editorIsOpen: !this.state.editorIsOpen })} viewblog={this.viewBlog} loading={this.state.loading} />
                <ViewBlog data={this.state.blogData} isOpen={this.state.blogIsOpen} editorIsOpen={this.state.editorIsOpen} toggle={() => this.setState({ blogIsOpen: !this.state.blogIsOpen })} url={this.state.editTarget.url} />
                <Row>
                    <Col>
                        <Filter update={this.filter} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.state.loading ? <Loader /> : (
                            <TableList
                                sort={this.sort}
                                sortParams={this.state.sort}
                                edit={this.edit}
                                headers={["Başlık", 'Yazar', 'Tarih', 'Son Düzenleme', 'Durum', "Url"]}
                                data={this.state.data}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Blog
