// React Base Component
import React from 'react'

// React Redux Connection
import { connect } from 'react-redux'
import { toggleUserList } from '../helper/ReduxActions'

// React Base Component
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// React Router Components
import {
    Link, Redirect
} from 'react-router-dom'

// Reactstrap Components
import {
    Button,
    Col,
    Media, Row
} from 'reactstrap'

// CSS Module
import styles from './modules/UserList.module.css'

class User extends React.Component {
    render() {
        return (
            <Link to={"/profile/" + this.props.data.uname} className={"text-decoration-none " + (this.props.online ? "text-dark" : "text-muted")}>
                <Media className={styles.UserListItem + " px-2 py-2 align-items-center rounded-pill"}>
                    <Media left>
                        <div className={styles.UserListImage} style={{ backgroundImage: `url(${this.props.data.avatar})` }} />
                    </Media>
                    <Media body className="px-3">
                        <h6>
                            {this.props.data.name}
                        </h6>
                        <div className="d-flex align-items-center">
                            <div className="pr-1" style={{ color: this.props.online ? "green" : "lightgray" }}>
                                &#11044;
                            </div>
                            <div>
                                {this.props.online ? "Online" : "Offline"}
                            </div>
                        </div>
                    </Media>
                </Media>
            </Link>
        )
    }
}

class UserList extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            fetch: true,
            data: {
                online: [],
                offline: []
            }
        }

        this.fetchUserList = () => {
            if (this.props.login) {
                fetch('/api/user-list').then(res => {
                    if (res.ok) {
                        return res.json()
                    } else if (res.status === 401) {
                        this.setState({ redirect: true })
                    } else {
                        throw new Error(res)
                    }
                }).then(data => {
                    this.setState({ data })
                }).catch(err => {
                    console.error(err.message)
                }).finally(() => {
                    this.setState({ loading: false, fetch: false })
                })
            }
        }
    }

    componentDidMount() {
        const index = setInterval(() => {
            this.setState({ fetch: true }, () => {
                this.fetchUserList()
            })
        }, 1000 * 20) // 20sec

        this.setState({ index }, () => {
            this.fetchUserList()
        })
    }

    componentDidUpdate() {
        if (this.state.fetch) {
            if (this.props.login && !(this.state.data.online.length || this.state.data.offline.length)) {
                this.fetchUserList()
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.index)
    }

    render() {
        return (
            this.state.redirect ? (
                <Redirect to="/logout" />
            ) : (
                <div className="position-fixed bg-white shadow" style={{
                    transition: "0.5s",
                    width: "320px",
                    maxWidth: "100% !important",
                    height: "100vh",
                    left: `calc(100vw - ${this.props.isOpen ? "320px" : "0px"})`
                }}>
                    {this.props.login ? (
                        <Row className="w-100 h-100 overflow-hidden" noGutters>
                            <Col className="overflow-auto py-2 px-2 h-100">
                                <Button color="dark" className="mb-3" onClick={() => this.props.toggleUserList(false)}>{<FontAwesomeIcon icon={faTimes} />}</Button>
                                {this.state.data.online.map(element => (
                                    <User key={element.uname} data={element} online={true} />
                                ))}
                                {this.state.data.offline.map(element => (
                                    <User key={element.uname} data={element} online={false} />
                                ))}
                            </Col>
                        </Row>
                    ) : (
                        <div className="text-center py-3">
                            Kullanıcıları görüntülemek için lütfen giriş yapın.
                        </div>
                    )}
                </div>
            )
        )
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.login,
        isOpen: state.userList
    }
}

const mapDipatchToProps = {
    toggleUserList
}

export default connect(mapStateToProps, mapDipatchToProps)(UserList)
